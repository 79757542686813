import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";

import { store } from "./app/store";
import { theme } from "./theme";
import App from "./App";

import "./styles/globals.css";

// swiper
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "react-datepicker/dist/react-datepicker.css";
import { Toaster } from "react-hot-toast";
import ApolloProvider from "./utils/apolloClient";
import AuthProvider from "./providers/AuthProvider";
import CurrentDateProvider from "./context/CurrentDateContext";
import ResourceFilterProvider from "./context/ResourceFilterContext";
import InfoDndProvider from "./context/InfoDndContext";
import ClashAltBookingProvider from "./context/ClashAltBooking";

ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
).render(
  <ApolloProvider>
    <ResourceFilterProvider>
      <CurrentDateProvider>
        <ClashAltBookingProvider>
          <InfoDndProvider>
            <AuthProvider>
              <Provider store={store}>
                <ChakraProvider theme={theme}>
                  <App />
                  <Toaster />
                </ChakraProvider>
              </Provider>
            </AuthProvider>
          </InfoDndProvider>
        </ClashAltBookingProvider>
      </CurrentDateProvider>
    </ResourceFilterProvider>
  </ApolloProvider>
);
