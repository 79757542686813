import * as AlertDialog from "@radix-ui/react-alert-dialog";

import { useAuthContext } from "../../providers/AuthProvider";
import { useContext } from "react";
import { ResourceFilterContext } from "../../context/ResourceFilterContext";

interface IModalOptions {
  isOpen: boolean;
  onClose: () => void;
}

export default function ModalFilterBoats({
  isOpen,
  onClose,
}: IModalOptions) {
  const { globalData } = useAuthContext();
  const { resourceSelect, setResourceSelect } = useContext(
    ResourceFilterContext
  );

  return (
    <AlertDialog.Root open={isOpen}>
      <AlertDialog.Portal className="z-[500]">
        <AlertDialog.Overlay
          onClick={() => {
            console.log("hello");
            onClose();
          }}
          className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
        />
        <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[14px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <div className="mb-3">
            <h3 className="font-bold text-2xl text-darkBlue">
              Filter by boats
            </h3>
          </div>

          <p className="text-lg text-darkBlue font-semibold">
            Pick the boats you would like to filter
          </p>

          <div className="grid grid-cols-3 gap-[6px] mt-[10px]">
            {globalData?.resourcesByBusiness.map((boat: any) => (
              <button
                key={boat.id}
                className={`h-auto rounded-xl bg-transparent relative overflow-hidden border-[4px] ${
                  resourceSelect === boat.name
                    ? "border-darkYellow"
                    : "border-white"
                }`}
                onClick={() => {
                  if (resourceSelect === boat.name) {
                    setResourceSelect("");
                    onClose();
                    return;
                  }

                  setResourceSelect(boat.name);
                  onClose();
                }}
              >
                <img
                  src={boat.picture}
                  className="w-full object-cover h-[110px] rounded-md overflow-hidden"
                  alt=""
                />

                <div className="overflow-hidden rounded-md absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-full h-full" />

                <div className="absolute bottom-[5px] left-[5px] w-full">
                  <p className="font-medium text-white text-xs uppercase text-left">
                    {boat.name.length > 10
                      ? `${boat.name.slice(0, 10)}..`
                      : boat.name}
                  </p>
                </div>
              </button>
            ))}
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
