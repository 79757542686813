import dayjs from "dayjs";
import { useQuery } from "@apollo/client";

import ModalFeedbackConfirm from "./FeedbackConfirm";
import ModalCalendar from "../Modals/Calendar";
import { useContext, useEffect, useState } from "react";
import { CurrentDateContext } from "../../context/CurrentDateContext";
import { useAuthContext } from "../../providers/AuthProvider";
import { BOOKING_EMPLOYEE_SCHEDULE } from "../../graphql/querys";
import Spinner from "../Spinner";
import toast from "react-hot-toast";

interface IDriverHeader {
  listConfirmations: string[] | undefined[];
  bookingIds: string[];
  setIsUpdated: (value: boolean) => void;
  refetchBookingsByEmployee: () => void;
}

enum STATUS_CONFIRM_ENUM {
  REJECTED = "REJECTED",
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
}

interface STATUS_CONFIRM_PROPS {
  value: STATUS_CONFIRM_ENUM;
}

export default function DriverHeader({
  listConfirmations,
  bookingIds,
  setIsUpdated,
  refetchBookingsByEmployee,
}: IDriverHeader) {
  const { currentDate } = useContext(CurrentDateContext);
  const [isOpenModalFeedback, setIsOpenModalFeedback] =
    useState<boolean>(false);

  // const onOpenModalFeedback = setIsOpenModalFeedback(true);
  // const onCloseModalFeedback = setIsOpenModalFeedback(false);

  const [isOpenModalCalendar, setIsOpenModalCalendar] =
    useState<boolean>(false);

  // const onOpenModalCalendar = setIsOpenModalCalendar(true);
  // const onCloseModalCalendar = setIsOpenModalCalendar(false);

  // const {
  //   isOpen: isOpenModalCalendar,
  //   onClose: onCloseModalCalendar,
  //   onOpen: onOpenModalCalendar,
  // } = useDisclosure();

  const [statusConfirm, setStatusConfirm] = useState<any>(
    STATUS_CONFIRM_ENUM.PENDING
  );

  useEffect(() => {
    setStatusConfirm(listConfirmations[0] as STATUS_CONFIRM_ENUM);
  }, [listConfirmations]);

  const { globalData } = useAuthContext();

  const {
    data,
    loading: loadingEmployeeSchedule,
    refetch: refetchEmployeeSchedule,
  } = useQuery(BOOKING_EMPLOYEE_SCHEDULE, {
    variables: {
      businessId: globalData?.business.id,
      date: dayjs(currentDate).startOf("day").format(),
    },
    fetchPolicy: "network-only",
  });

  const currentState = data?.bookingsEmployeeSchedule.find(
    (booking: any) => booking.employee.id === globalData?.me.id
  );

  const today = dayjs();
  const day = dayjs(currentDate)
    .subtract(globalData.business.publishDaysBefore, "day")
    .hour(parseInt(globalData.business.publishTime.slice(0, 2)))
    .minute(parseInt(globalData.business.publishTime.slice(3, 5)))
    .second(0);

  return (
    <div className="flex px-5 py-5 justify-between items-center">
      <ModalFeedbackConfirm
        bookingIds={bookingIds}
        onClose={() => setIsOpenModalFeedback(false)}
        isOpen={isOpenModalFeedback}
        statusConfirm={currentState?.estado}
        setIsUpdated={setIsUpdated}
        refetchBookingsByEmployee={refetchEmployeeSchedule}
      />

      <ModalCalendar
        isOpen={isOpenModalCalendar}
        onClose={() => setIsOpenModalCalendar(false)}
      />

      <div>
        <button className="text-darkBlue p-0 h-auto bg-transparent">
          <span className="block text-2xl font-bold">
            {globalData?.business.name}
          </span>
        </button>
      </div>

      <div className="grid grid-cols-2 gap-3">
        {loadingEmployeeSchedule ? (
          <div className="grid w-[40px] place-items-center">
            <Spinner />
          </div>
        ) : (
          <button
            className={`${
              currentState?.estado === STATUS_CONFIRM_ENUM.REJECTED
                ? "border-danger"
                : currentState?.estado ===
                  STATUS_CONFIRM_ENUM.CONFIRMED
                ? "border-success"
                : currentState?.estado === undefined
                ? "border-darkBlue"
                : day.isAfter(today)
                ? "border-darkBlue"
                : "border-darkYellow"
            } rounded-sm p-[10px] text-[28px] h-auto bg-transparent text-darkBlue border`}
            onClick={() => {
              if (day.isAfter(today)) {
                toast(
                  "You still cannot accept reservations for this day",
                  {
                    icon: "⚠️",
                  }
                );
                return;
              }

              setIsOpenModalFeedback(true);
            }}
          >
            <img
              src={
                currentState?.estado === STATUS_CONFIRM_ENUM.REJECTED
                  ? "/icons/check-icon-rejected.svg"
                  : currentState?.estado ===
                    STATUS_CONFIRM_ENUM.CONFIRMED
                  ? "/icons/check-icon-accepted.svg"
                  : currentState?.estado === undefined
                  ? "/icons/check-icon-normal.svg"
                  : day.isAfter(today)
                  ? "/icons/check-icon-normal.svg"
                  : "/icons/check-icon-pending.svg"
              }
              alt=""
            />
          </button>
        )}

        <button
          className="p-[10px] text-[28px] h-auto bg-transparent text-darkBlue border border-darkBlue rounded-sm"
          onClick={() => setIsOpenModalCalendar(true)}
        >
          <img src="/icons/calendar-icon.svg" alt="" />
        </button>
      </div>
    </div>
  );
}
