export const useFilterByDateBookings = (data: any) => {
  const groups = data?.reduce((groups: any, booking: any) => {
    const date = booking?.date.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(booking);
    return groups;
  }, {});

  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      bookings: groups[date],
    };
  });

  return groupArrays;
};
