import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormControl, Input } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import useStorage from "../../../hooks/useStorage";
import BackButton from "../../../components/BackButton";
import { CREATE_RESOURCE_MUTATION } from "../../../graphql/mutations";
import { ICreateBoat } from "../../../interfaces/boat.interface";
import Spinner from "../../../components/Spinner";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().max(30, "Character limit of 30"),
  picture: yup.string(),
  description: yup.string()
});

export default function CreateBoat() {
  const refButtonImage = useRef<any>(null);

  const [uploadUrlFile, setUploadUrlFile] = useState(null);

  const [createResourceMutation, { loading }] = useMutation(
    CREATE_RESOURCE_MUTATION
  );
  const navigate = useNavigate();

  const [uploading, uploadFileToAws] = useStorage();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      picture: "",
      description: "",
    },
  });

  const handleFile = async (e: any) => {
    const files = e.target.files;

    if (files) {
      const file: any = files[0] ?? "";
      console.log(file);

      uploadFileToAws(file)
        .then((res: any) => {
          console.log("RES ON UPLOAD FILE uploadFileToAws", res);
          setUploadUrlFile(res.url);
          setValue("picture", res.url);
        })
        .catch((err: any) => {
          console.log("ERROR ON UPLOAD FILE uploadFileToAws", err);
        });
    }
  };

  function onSubmit(values: ICreateBoat) {
    createResourceMutation({
      variables: {
        data: values,
      },
    }).then((res) => {
      // notification
      if (res.data?.createResource.status === 200) {
        const notify = () =>
          toast.success("Boat created successfully");
        notify();
        return navigate(-1);
      }

      console.log({ res })

      const notify = () =>
        toast("A boat with that name already exists", {
          icon: "⚠️",
        });
      notify();
    });
  }
  return (
    <div>
      <BackButton goBackRoute="/list-boats" title="Add a boat" />

      {/* @ts-ignore */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex px-5 justify-between flex-col h-[calc(100vh_-_88px_-_32px)]">
          <div>
            <FormControl isInvalid={!!errors.name}>
              <Input
                className="form-control-input"
                type="text"
                id="name"
                placeholder="Boat's name"
                _focusWithin={{ borderColor: "vvas.darkBlue" }}
                {...register("name", {
                  required: "This is required",
                  max: "Limit is 30 characters",
                })}
              />
              <p className="text-danger text-sm mt-2">
                {errors.name && errors.name.message}
              </p>
            </FormControl>

            <div className="flex mt-5">
              {uploadUrlFile && (
                <div className="mr-5 w-[120px] relative overflow-hidden rounded-md">
                  <div className="w-full h-full bg-neutral-900/40 absolute top-0 left-0 z-50" />

                  <img
                    className="rounded-md w-full h-[120px] object-cover"
                    src={uploadUrlFile}
                    alt=""
                  />
                </div>
              )}

              <input
                ref={refButtonImage}
                type="file"
                style={{ display: "none" }}
                name="file"
                id="file"
                onChange={handleFile}
              />

              <button
                type="button"
                className="bg-transparent block border border-darkBlue w-[130px] h-[120px] text-darkBlue p-[10px] rounded-md font-medium"
                onClick={() => refButtonImage.current?.click()}
              >
                {uploadUrlFile ? `Change Image` : "Add Image"}
              </button>
            </div>
          </div>

          <button
            type="submit"
            disabled={!getValues().picture}
            className={`bg-darkBlue h-[54px] text-white rounded-md font-medium text-lg ${
              !getValues().picture ? "opacity-50" : "opacity-100"
            }`}
          >
            {!!isSubmitting || !!loading || !!uploading ? (
              <Spinner />
            ) : (
              "Add Boat"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
