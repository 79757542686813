import dayjs from "dayjs";
// import "dayjs/locale/es"; // Opcional: establece el idioma si lo deseas
import { createContext, useEffect, useState } from "react";

export const CurrentDateContext = createContext<any>(null);

export default function CurrentDateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentDate, setCurrentDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const [currentPosition, setCurrentPosition] = useState<any>(0);
  const [locationItem, setLocationItem] = useState<any>(null);

  const getNextDays = (fechaActual: any) => {
    const diasSemana = [];

    for (let i = 0; i <= 90; i++) {
      const fecha = dayjs(fechaActual).add(i, "day");
      const dia = fecha.format("DD");
      diasSemana.push({
        fecha: fecha.format("YYYY-MM-DD"),
        dia,
        pos: i,
      });
    }

    return diasSemana;
  };

  const getPreviousDays = (fechaActual: any) => {
    const diasSemana = [];

    for (let i = 30; i >= 1; i--) {
      const fecha = dayjs(fechaActual).subtract(i, "day");
      const dia = fecha.format("DD");
      diasSemana.push({
        fecha: fecha.format("YYYY-MM-DD"),
        dia,
        pos: i,
      });
    }

    return diasSemana;
  };

  const nextDays = getNextDays(dayjs().format("YYYY-MM-DD"));
  const previousDays = getPreviousDays(dayjs().format("YYYY-MM-DD"));
  const arraysDayComplete = [...previousDays, ...nextDays];

  const arraysDayCompletePos = arraysDayComplete.map(
    (item, index) => {
      return {
        ...item,
        pos: index + 1,
      };
    }
  );

  useEffect(() => {
    const currentPosition = arraysDayCompletePos.find(
      (day) => dayjs(day.fecha).format("YYYY-MM-DD") === currentDate
    );
    setCurrentPosition(currentPosition?.pos);
  }, [currentDate]);

  return (
    <CurrentDateContext.Provider
      value={{
        currentDate,
        setCurrentDate,
        position: currentPosition,
        locationItem,
        setLocationItem,
        arraysDayComplete,
      }}
    >
      {children}
    </CurrentDateContext.Provider>
  );
}
