import { useState, useEffect, useContext } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { DateTime } from "luxon";

import {
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

import { getDayjsFromOffset } from "../../utils/fechas";
import {
  CREATE_BOOKING_MUTATION,
  DELETE_BOOKING,
} from "../../graphql/mutations";
import SelectTime2 from "../../components/SelectTime2";
import BackButton from "../../components/BackButton";
import ButtonSelect from "../../components/Booking/ButtonSelect";
import { BOATS, DRIVERS, LOCATIONS } from "../../data";
import {
  useModalHeightImage,
  useModalHeightText,
} from "../../helpers";
import { Input, Modal } from "../../ui";
import { useQuery } from "@apollo/client";

import { useAuthContext } from "../../providers/AuthProvider";
import {
  GLOBALDATA_QUERY,
  BOOKING_QUERY,
} from "../../graphql/querys";
import { IDriver } from "../../interfaces";
import { CurrentDateContext } from "../../context/CurrentDateContext";
import Calendar from "../../components/Calendar";
import SpinnerCircle from "../../components/Loader/Spinner";
import Spinner from "../../components/Spinner";

export default function CreateBooking() {
  // Modal States
  const { globalData } = useAuthContext();
  const { id, type } = useParams();

  console.log({ globalData });

  const [cleaning, setCleaning] = useState(
    type === "cleaning" ? true : false
  );

  const {
    data: dataBooking,
    loading,
    error,
  } = useQuery(BOOKING_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      id,
    },
  });

  const [deleteBooking, { loading: loadingDeletBooking }] =
    useMutation(DELETE_BOOKING);

  const [createBookingMutation, { loading: loadingCreateBooking }] =
    useMutation(CREATE_BOOKING_MUTATION);

  const [showModalBoat, setShowModalBoat] = useState<boolean>(false);
  const [showModalDriver, setShowModalDriver] =
    useState<boolean>(false);

  const [showModalDate, setShowModalDate] = useState<boolean>(false);
  const [showModalLocation, setShowModalLocation] =
    useState<boolean>(false);

  const [showModalTime, setShowModalTime] = useState<boolean>(false);
  const [showModalTime2, setShowModalTime2] =
    useState<boolean>(false);

  console.log({ driversLenght: DRIVERS.length });
  // variables
  const heightModalTime = useModalHeightImage(
    globalData?.resourcesByBusiness.length
  );
  const heightModalDriver = useModalHeightImage(
    globalData?.employees.length
  );
  const heightModalLocation = useModalHeightText(LOCATIONS.length);

  const { currentDate } = useContext(CurrentDateContext);

  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: dataGlobal,
    loading: loadingGlobal,
    error: errorGlobal,
    refetch: refetchGlobal,
  } = useQuery(GLOBALDATA_QUERY);

  useEffect(() => {
    refetchGlobal();
  }, [location]);

  // Booking State
  const [timeStart, setTimeStart] = useState<{
    value: string | undefined;
    prefix: string | undefined;
  }>({ value: "", prefix: "" });

  const [timeEnd, setTimeEnd] = useState<{
    value: string | undefined;
    prefix: string | undefined;
  }>({ value: "", prefix: "" });

  useEffect(() => {
    console.log("change on timeStart o timeEnd");
    console.log("times", timeStart, timeEnd);
  }, [timeStart, timeEnd]);

  const [date, setDate] = useState<any>(
    dayjs(new Date(currentDate)).utc().format("YYYY-MM-DD") //"dd MMM DD"
  );

  const [bookingInfo, setBookingInfo] = useState<any>({
    boatId: "",
    boatName: "",
    clientName: "",
    location: "",
    pickup: "",
    driverId: [],
    description: "",
  });

  const time = [
    { value: "01:00", label: "01:00" },
    { value: "02:00", label: "02:00" },
    { value: "03:00", label: "03:00" },
    { value: "04:00", label: "04:00" },
    { value: "05:00", label: "05:00" },
    { value: "06:00", label: "06:00" },
    { value: "07:00", label: "07:00" },
    { value: "08:00", label: "08:00" },
    { value: "09:00", label: "09:00" },
    { value: "10:00", label: "10:00" },
    { value: "11:00", label: "11:00" },
    { value: "12:00", label: "12:00" },
  ];

  const prefixs = [
    { value: "am", label: "AM" },
    { value: "pm", label: "PM" },
  ];

  // Functions

  // const {
  //   isOpen: isOpenDeleteBooking,
  //   onClose: onCloseDeleteBooking,
  //   onOpen: onOpenDeleteBooking,
  // } = useDisclosure();

  const [isOpenDeleteBooking, setIsOpenDeleteBooking] =
    useState<boolean>(false);

  const onCloseDeleteBooking = () => setIsOpenDeleteBooking(false);
  const onOpenDeleteBooking = () => setIsOpenDeleteBooking(true);

  const [offsetStartDate, setOffsetStartDate] = useState<number>(
    location.state ? location.state.item.from : 0
  );
  const [offsetDuration, setOffsetDuration] = useState<number>(
    +offsetStartDate + 2 || 24
  );

  useEffect(() => {
    if (dataBooking) {
      const { booking } = dataBooking;

      console.log("dataBooking", dataBooking);

      setBookingInfo((p: any) => {
        return {
          boatId: booking.resource?.id,
          boatName: booking.resource?.name,
          clientName: booking.clientName,
          location: booking.location.id,
          pickup: booking.pickup,
          driverId: booking.bookingEmployees.map(
            (i: any) => i.employee.id
          ),
          description: booking.description,
        };
      });

      if (booking.type === "CLEANING") setCleaning(true);

      const { startDate, endDate } = booking;

      const startDateTimeInUTC = DateTime.fromISO(
        dayjs(startDate).toISOString(),
        { zone: "utc" }
      );
      // Cambia la zona horaria a "America/New_York"
      const startDateTimeInNewYork = startDateTimeInUTC.setZone(
        globalData.business.timeZone
      );

      const endDateTimeInUTC = DateTime.fromISO(
        dayjs(endDate).toISOString(),
        { zone: "utc" }
      );
      // Cambia la zona horaria a "America/New_York"
      const endDateTimeInNewYork = endDateTimeInUTC.setZone(
        globalData.business.timeZone
      );

      setTimeStart({
        value: startDateTimeInNewYork.toFormat("HH:mm"), //   dayjs(startDate).format("hh:mm"),
        prefix: startDateTimeInNewYork.toFormat("a"), // dayjs(startDate).format("A"),
      });

      setTimeEnd({
        value: endDateTimeInNewYork.toFormat("HH:mm"), //   dayjs(endDate).format("hh:mm"),
        prefix: endDateTimeInNewYork.toFormat("a"), // dayjs(endDate).format("A"),
      });

      // setDate(dayjs(startDate).utc().format("dd MMM DD"));
      setDate( dayjs(new Date(startDate)).utc().format("YYYY-MM-DD")   );
    }
  }, [dataBooking]);

  useEffect(() => {
    if (dataBooking) {
      const { booking } = dataBooking;

      const { startDate, endDate } = booking;

      // const horaBase = dayjs(startDate).hour(6).minute(0);
      const horaBase = dayjs(
        DateTime.fromFormat(
          `${dayjs(startDate).format(
            "YYYY-MM-DD"
          )} ${globalData.business.startTime.substr(0, 5)}`,
          "yyyy-MM-dd hh:mm",
          { zone: globalData.business.timeZone }
        )
          .toUTC()
          .toISO()
      );

      console.log(
        "horabase",
        startDate,
        dayjs(startDate).hour(6).minute(0)
      );
      console.log("horabase luxon", horaBase);

      const startDateBase = dayjs(startDate);
      const endDateBase = dayjs(endDate);

      const diffInMinutes = endDateBase.diff(startDate, "minute");

      setOffsetStartDate(
        startDateBase.diff(horaBase, "minutes") / 30
      );
      setOffsetDuration(
        diffInMinutes / 30 +
          startDateBase.diff(horaBase, "minutes") / 30
      );
    }
  }, [showModalTime, dataBooking, offsetStartDate, location]);

  useEffect(() => {
    if (location.state) {
      const { item, currentDate, locationId } = location.state;

      setBookingInfo((p: any) => {
        return {
          ...p,
          driverId: [item.driverObj.id],
          location: locationId,
          // boatId: booking.resource?.id,
          // boatName: booking.resource?.name,
          // clientName: booking.clientName,
          // pickup: booking.pickup,
          // description: booking.description,
        };
      });

      setDate(dayjs(currentDate).utc().format("YYYY-MM-DD"));

      // const { startDate, endDate } = booking;

      const startDate = getDayjsFromOffset(60 * 6, item.from);
      const endDate = getDayjsFromOffset(60 * 6, item.from + 2);

      console.log("startDate", startDate);

      setTimeStart({
        value: dayjs(startDate).format("hh:mm"),
        prefix: dayjs(startDate).format("A"),
      });

      setTimeEnd({
        value: dayjs(endDate).format("hh:mm"),
        prefix: dayjs(endDate).format("A"),
      });

      // setDate(dayjs(startDate).format('YYYY-MM-DD'));
    }
  }, [location]);

  /* It is used to detect if the user has already made an attempt to create the reservation */
  const [sending, setSending] = useState<boolean>(false);

  const handleCreateBooking = (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setSending(true);

    if (
      !bookingInfo.clientName ||
      !bookingInfo.location ||
      bookingInfo.driverId.length <= 0 ||
      !date ||
      !timeStart.value ||
      !timeStart.prefix ||
      !timeEnd.value ||
      !timeEnd.prefix
    ) {
      return toast.error("You are missing some required fields");
    }



    const payload: any = {
      driversId: bookingInfo.driverId,
      clientName: bookingInfo.clientName,
      pickup: bookingInfo.pickup,
      description: bookingInfo.description || "",
      from: timeStart,
      to: timeEnd,
      date: dayjs(date).format("YYYY-MM-DD"),
      locationId: bookingInfo.location,
    };

    if (id) payload.id = id;
    if (bookingInfo.boatId) {
      payload.boatId = parseInt(bookingInfo.boatId);
    } else {
      payload.boatId = null;
    }
    if (cleaning) payload.type = "CLEANING";
    else payload.type = "TIME";

    // alert(JSON.stringify(payload, null, 3)
    // );
    // return;

    createBookingMutation({
      variables: {
        data: payload,
      },
    })
      .then((res) => {
        console.log(res);

        const { message, status } = res.data.createBooking2;

        if (status == 200) {
          if (id) toast.success("Reservation Modified Successfully!");
          else toast.success("Reservation created!");
          navigate("/");
        } else {
          toast.error(message);
        }
      })
      .catch((err) => {
        toast.error("Booking fail!");
      });

    // console.log(payload);
    // alert(JSON.stringify(payload, null, 3));
    // return toast.success("Booking created!");
  };

  // Get info by id
  const boatImageSelect: any = BOATS.find(
    (item) => item.id === bookingInfo.boatId
  );

  const locationSelect: any = globalData?.locationsByBusiness?.find(
    (location: any) => location.id === bookingInfo.location
  );

  const findDriverById = (id: string) => {
    return dataGlobal.employees.find(
      (driver: any) => driver.id === id
    );
  };

  const removeDriverSelect = (id: string) => {
    setBookingInfo((bookingInfo: any) => ({
      ...bookingInfo,
      driverId: bookingInfo.driverId.filter(
        (item: any) => item !== id
      ),
    }));
  };

  const handleDeleteBooking = () => {
    deleteBooking({
      variables: {
        id,
      },
    })
      .then((r) => {
        console.log(r);
        toast.success("Bookings deleted!");
        navigate("/");
      })
      .catch((e: any) => {
        console.log("error", e);
        toast.error("Error!");
      });
  };

  const boatSelected = globalData?.resourcesByBusiness?.find(
    (boat: any) => boat.id === bookingInfo.boatId
  );

  console.log({ timeStart });

  if (loading)
    return (
      <div className="grid w-screen h-screen place-items-center">
        <SpinnerCircle />
      </div>
    );

  return (
    <div>
      <BackButton
        smallPadding
        goBackRoute="/"
        title={
          cleaning
            ? `boat cleaning`
            : `${id ? "Edit" : "Create a"} reservation`
        }
      />

      <AlertDialog.Root open={isOpenDeleteBooking}>
        <AlertDialog.Portal className="z-[500]">
          <AlertDialog.Overlay
            onClick={() => {
              console.log("hello");
              onCloseDeleteBooking();
            }}
            className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
          />
          <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-1/2 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] -translate-y-1/2 rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <header>
              <h3 className="font-bold text-darkBlue text-2xl leading-[1.1]">
                Are you sure you want to cancel this reservation?
              </h3>
            </header>

            <main className="py-5">
              <p className="leading-[1.3]">
                This will delete this reservation permanently
              </p>
            </main>

            <footer className="flex">
              <button
                className="w-full text-white uppercase bg-darkBlue py-3 h-[54px] rounded-md grid mr-2 place-items-center"
                type="button"
                onClick={handleDeleteBooking}
              >
                {loadingDeletBooking ? <Spinner /> : "Delete"}
              </button>
              <button
                className="w-full text-darkBlue uppercase bg-lightBlue py-3 h-[54px] rounded-md"
                onClick={onCloseDeleteBooking}
              >
                Close
              </button>
            </footer>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>

      <AlertDialog.Root open={showModalBoat}>
        <AlertDialog.Portal className="z-[500]">
          <AlertDialog.Overlay
            onClick={() => {
              console.log("hello");
              setShowModalBoat(false);
            }}
            className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
          />
          <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <div className="grid gap-4 grid-cols-2">
              {globalData?.resourcesByBusiness?.map((boat: any) => (
                <button
                  key={boat.id}
                  className={`${
                    bookingInfo.boatId === boat.id
                      ? "outline-darkBlue"
                      : "outline-transparent"
                  } overflow-hidden relative rounded-sm outline-offset-2 outline-4`}
                  onClick={() => {
                    setShowModalBoat(false);
                    setBookingInfo({
                      ...bookingInfo,
                      boatId: boat.id,
                      boatName: boat.name,
                    });
                  }}
                >
                  <img
                    src={boat.picture}
                    alt=""
                    className="w-full h-[165px] object-cover"
                  />

                  <div className="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.4)] z-10" />

                  <div className="flex items-center w-full absolute bottom-2 left-0">
                    <p className="whitespace-pre-line text-left uppercase font-medium text-white z-20 p-[0_10px]">
                      {boat.name}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>

      <AlertDialog.Root open={showModalDriver}>
        <AlertDialog.Portal className="z-[500]">
          <AlertDialog.Overlay
            onClick={() => {
              console.log("hello");
              setShowModalDriver(false);
            }}
            className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
          />
          <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <div className="grid gap-4 grid-cols-2">
              {dataGlobal.employees.map((driver: IDriver) => (
                <button
                  className={`${
                    bookingInfo.driverId === driver.id
                      ? "outline-darkBlue"
                      : "outline-transparent"
                  } rounded-md overflow-hidden h-auto outline-4 relative outline-offset-2`}
                  key={driver.id}
                  onClick={() => {
                    if (bookingInfo.driverId.includes(driver.id))
                      return toast.error(
                        "The driver has already been selected"
                      );

                    setShowModalDriver(false);
                    setBookingInfo({
                      ...bookingInfo,
                      driverId: [...bookingInfo.driverId, driver.id],
                    });
                  }}
                >
                  <img
                    src={driver.picture}
                    alt=""
                    className="w-full h-[165px] object-cover"
                  />

                  <div className="absolute top-0 letf-0 w-full h-full bg-[rgba(0,0,0,0.4)] z-10" />

                  <p className="uppercase font-medium text-white z-20 absolute bottom-2 left-2">
                    {driver.name}
                  </p>
                </button>
              ))}
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>

      <AlertDialog.Root open={showModalDate}>
        <AlertDialog.Portal className="z-[500]">
          <AlertDialog.Overlay
            onClick={() => {
              console.log("hello");
              setShowModalDate(false);
            }}
            className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
          />
          <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed bottom-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <Calendar
              selectedDay={date}
              // TODO: fix typo later
              setSelectedDay={(v: any) => {
                console.log("setDate", v);
                setDate(v);
                setShowModalDate(false);
              }}
            />
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>

      <SelectTime2
        offset1={offsetStartDate}
        offsetDuration={offsetDuration}
        onChange={(d: any) => {
          console.log("onchange selectime2", d);
          // {value: '06:00', prefix: 'am'} {value: '08:00', prefix: 'am'}
          setTimeStart(d[0]);
          setTimeEnd(d[1]);
        }}
        show={showModalTime2}
        onClose={setShowModalTime2}
      />

      <AlertDialog.Root open={showModalTime}>
        <AlertDialog.Portal className="z-[500]">
          <AlertDialog.Overlay
            onClick={() => {
              console.log("hello");
              setShowModalTime(false);
            }}
            className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
          />
          <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed bottom-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <div className="mb-[25px]">
              <p className="mb-2">Start time:</p>
              <div className="grid grid-cols-[2fr_1fr] gap-5">
                <Select
                  options={time}
                  placeholder="Select time"
                  maxMenuHeight={100}
                  onChange={(e: any) =>
                    setTimeStart({ ...timeStart, value: e?.value })
                  }
                />
                <Select
                  options={prefixs}
                  placeholder="prefix"
                  onChange={(e: any) =>
                    setTimeStart({ ...timeStart, prefix: e?.value })
                  }
                />
              </div>
            </div>

            <div>
              <p className="mb-2">End time:</p>
              <div className="grid grid-cols-[2fr_1fr] gap-5">
                <Select
                  options={time}
                  placeholder="Select time"
                  maxMenuHeight={100}
                  onChange={(e: any) =>
                    setTimeEnd({ ...timeEnd, value: e?.value })
                  }
                />
                <Select
                  options={prefixs}
                  placeholder="prefix"
                  onChange={(e: any) =>
                    setTimeEnd({ ...timeEnd, prefix: e?.value })
                  }
                />
              </div>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>

      <AlertDialog.Root open={showModalLocation}>
        <AlertDialog.Portal className="z-[500]">
          <AlertDialog.Overlay
            onClick={() => {
              console.log("hello");
              setShowModalLocation(false);
            }}
            className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
          />
          <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed bottom-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <header className="pb-3">
              <h3 className="text-2xl font-bold text-darkBlue">
                Select location
              </h3>
            </header>

            <div className="flex flex-col">
              {globalData?.locationsByBusiness?.map(
                (location: any) => (
                  <button
                    className={`ring-2 ${
                      bookingInfo.location === location.id
                        ? "ring-darkBlue"
                        : "ring-transparent"
                    } mb-1 text-darkBlue rounded-md p-2 overflow-hidden h-auto text-left bg-transparent text-xl block`}
                    key={location.id}
                    onClick={() => {
                      setShowModalLocation(false);
                      setBookingInfo({
                        ...bookingInfo,
                        location: location.id,
                      });
                    }}
                  >
                    {location.name}
                  </button>
                )
              )}
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>

      {/* Form */}
      <form onSubmit={handleCreateBooking} className="-mt-1">
        <div className="pb-5">
          <div className="pl-5 overflow-x-scroll hide-scrollbar">
            {!cleaning && (
              <div className="flex w-max py-1 gap-x-3 mb-4">
                {globalData?.resourcesByBusiness?.map((boat: any) => (
                  <button
                    type="button"
                    key={boat.id}
                    className={`w-[90px] rounded-md ${
                      bookingInfo.boatId === boat.id
                        ? "ring-darkYellow"
                        : "ring-transparent"
                    } overflow-hidden relative rounded-md outline-offset-2 ring-4`}
                    onClick={() => {
                      setBookingInfo({
                        ...bookingInfo,
                        boatId: boat.id,
                        boatName: boat.name,
                      });
                    }}
                  >
                    <img
                      src={boat.picture}
                      alt=""
                      className="w-full h-[90px] object-cover rounded-md"
                    />

                    <div className="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.4)] z-10" />

                    <div className="flex items-center w-full absolute bottom-[6px] left-0">
                      <p className="whitespace-pre-line text-sm text-left uppercase font-medium text-white z-20 p-[0_6px]">
                        {boat.name}
                      </p>
                    </div>
                  </button>
                ))}
                <div className="w-2 h-full" />
              </div>
            )}
          </div>

          <div className="px-5">
            <p className="font-bold uppercase text-darkBlue">
              Date and time
            </p>

            <div className="grid mt-[5px] grid-cols-[1.35fr_1fr] gap-5">
              <ButtonSelect
                isEmpty={
                  sending &&
                  (!timeStart.value ||
                    !timeStart.prefix ||
                    !timeEnd.prefix ||
                    !timeEnd.value)
                }
                onClick={() => setShowModalTime2(true)}
                isValue={!!timeStart.value && !!timeStart.value}
              >
                {timeStart.value ? timeStart.value : "xx"}{" "}
                {timeStart.prefix ? timeStart.prefix : "xx"}
                {" / "}
                {timeEnd.value ? timeEnd.value : "xx"}{" "}
                {timeEnd.prefix ? timeEnd.prefix : "xx"}{" "}
              </ButtonSelect>
              <ButtonSelect
                isValue={!!date}
                onClick={() => setShowModalDate(true)}
              >
                {dayjs(date).format("dd MMM DD")}
              </ButtonSelect>
            </div>
          </div>

          <div className="mt-4 px-5">
            <p className="font-bold uppercase text-darkBlue mb-[5px]">
              Client and location
            </p>

            <Input
              isEmpty={sending && !bookingInfo.clientName}
              placeholder="Client's name"
              borderColor="vvas.lightBlue"
              value={bookingInfo.clientName}
              onChange={(e: any) =>
                setBookingInfo({
                  ...bookingInfo,
                  clientName: e.target.value,
                })
              }
            />
          </div>

          <div className="grid mt-[14px] gap-5 grid-cols-2 px-5">
            <ButtonSelect
              isEmpty={sending && !bookingInfo.location}
              isValue={!!locationSelect}
              onClick={() => setShowModalLocation(true)}
            >
              {locationSelect?.name || "Location"}
            </ButtonSelect>
            <Input
              borderColor="vvas.lightBlue"
              placeholder="Pickup"
              _placeholder={{ color: "vvas.lightBlue" }}
              value={bookingInfo.pickup}
              onChange={(e: any) =>
                setBookingInfo({
                  ...bookingInfo,
                  pickup: e.target.value,
                })
              }
            />
          </div>

          <div className="mt-4">
            <p className="ml-5 font-bold text-darkBlue uppercase mb-1">
              Driver{" "}
              {sending && bookingInfo.driverId.length <= 0 && (
                <span className="text-red-500 lowercase font-normal text-sm">
                  is required
                </span>
              )}
            </p>

            <div className="pl-5 overflow-x-scroll hide-scrollbar">
              <div className="flex w-max gap-x-3 py-1">
                {/* <p>
                  { bookingInfo?.driverId.map(i=>i).join() }
                </p> */}
                {dataGlobal?.employees.map((driver: IDriver) => {
                  const isSelected = bookingInfo?.driverId.find(
                    (id: string) => id === driver?.id
                  );

                  return (
                    <button
                      type="button"
                      className={`${
                        isSelected
                          ? "ring-darkYellow"
                          : "ring-transparent"
                      } ring-4 w-[90px] h-[90px] rounded-md overflow-hidden relative`}
                      onClick={() => {
                        if (isSelected === driver?.id) {
                          setBookingInfo({
                            ...bookingInfo,
                            driverId: bookingInfo.driverId.filter(
                              (item: string) => item !== driver?.id
                            ),
                          });
                          return;
                        }

                        setShowModalDriver(false);
                        setBookingInfo({
                          ...bookingInfo,
                          driverId: [
                            ...bookingInfo.driverId,
                            driver.id,
                          ],
                        });
                      }}
                    >
                      <img
                        src={driver.picture}
                        alt=""
                        className="w-full h-[90px] object-cover"
                      />

                      <div className="absolute top-0 letf-0 w-full h-full bg-[rgba(0,0,0,0.4)] z-10" />

                      <p className="uppercase font-medium text-white z-20 absolute bottom-2 left-2">
                        {driver.name}
                      </p>
                    </button>
                  );
                })}

                <div className="w-2 h-full" />
              </div>
            </div>
          </div>

          <div className="mt-4 px-5">
            <textarea
              className="rounded-md resize-none h-[80px] border w-full p-3 border-lightBlue"
              placeholder="Notes"
              value={bookingInfo.description}
              onChange={(e: any) =>
                setBookingInfo({
                  ...bookingInfo,
                  description: e.target.value,
                })
              }
            />
          </div>

          <div className="grid pt-4 pb-2 px-5">
            <button
              disabled={loadingCreateBooking}
              className="rounded-md text-white font-medium text-lg uppercase bg-darkBlue h-[54px]"
              type="submit"
            >
              {loadingCreateBooking ? (
                <Spinner />
              ) : id ? (
                "SAVE CHANGES"
              ) : (
                "SAVE RESERVATION"
              )}
            </button>

            <button
              type="button"
              className={`h-[54px] text-white rounded-md text-lg font-medium uppercase bg-danger mt-[10px] ${
                id ? "block" : "hidden"
              }`}
              onClick={onOpenDeleteBooking}
            >
              {loadingDeletBooking ? (
                <Spinner />
              ) : (
                "DELETE RESERVATION"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
