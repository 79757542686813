import React, { useEffect } from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,

} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuthContext } from "../providers/AuthProvider";



import Home from "../screens/Home";
import Settings from "../screens/Settings";
import LoginQuick from "../screens/LoginQuick";

import ListDrivers from "../screens/Settings/Drivers/ListDrivers";
import ListBoats from "../screens/Settings/Boats/ListBoats";
import ListLocations from "../screens/Settings/Locations/ListLocations";
import LegalStuff from "../screens/Settings/LegalStuff";
import Notifications from "../screens/Settings/Notifications";

import CreateDriver from "../screens/Settings/Drivers/Create";
import CreateBoat from "../screens/Settings/Boats/Create";
import CreateLocation from "../screens/Settings/Locations/Create";

import EditBoat from "../screens/Settings/Boats/Edit";
import EditDriver from "../screens/Settings/Drivers/Edit";
import EditLocation from "../screens/Settings/Locations/Edit";

import CreateBooking from "../screens/Booking/Create";
import BookingView from "../screens/Booking/View";

import DriverConfirm from "../screens/DriverConfirm";
import DriverHome from "../screens/Driver/Home";
import DriverSettings from "../screens/Driver/Settings";
import DriverBookingView from "../screens/Driver/Booking";
import ViewReservToAccept from "../screens/ViewReservToAccept";
import Auth from "../screens/Auth";
import LoginDriver from "../screens/LoginDriver";
import HourConfirm from "../screens/Settings/Drivers/Confirm";

import DragTest from "../screens/AlphaFeatures/DragTest";
import MacroView from "../screens/MacroView";
import SpinnerCircle from "../components/Loader/Spinner";



const Router: React.FC = () => {
  const { authenticated, globalData, loadingGlobalData } =
    useAuthContext();





  if (loadingGlobalData)
    return (
      <div className="p-8 text-center w-screen h-screen grid place-items-center">
        <SpinnerCircle />
      </div>
    );

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {!authenticated ? (
          <>
            <Route path="/*" element={<Auth />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/login-admin" element={<LoginQuick />} />
            <Route path="/login-driver" element={<LoginDriver />} />
          </>
        ) : (
          <>
            {/* <Route p""ath="/auth" element={<Navigate to="/" />} /> */}
            <Route path="/macro-view" element={<MacroView />} />
            <Route
              path="/login-admin"
              element={<Navigate to="/" />}
            />
            <Route
              path="/login-driver"
              element={<Navigate to="/" />}
            />

            <Route path="/hour-confirm" element={<HourConfirm />} />

            {globalData.me.role === "ADMIN" ? (
              <Route path="/" element={<Home />} />
            ) : (
              <Route path="/" element={<DriverHome />} />
            )}

            {/* Settings */}
            <Route path="/settings" element={<Settings />} />
            <Route path="/list-drivers" element={<ListDrivers />} />
            <Route path="/list-boats" element={<ListBoats />} />
            <Route
              path="/list-locations"
              element={<ListLocations />}
            />
            <Route path="/legal-stuff" element={<LegalStuff />} />

            <Route
              path="/notifications"
              element={<Notifications />}
            />
            <Route
              path="/driver-notifications"
              element={<Notifications />}
            />

            {/* Create */}
            <Route path="/create-driver" element={<CreateDriver />} />
            <Route path="/create-boat" element={<CreateBoat />} />
            <Route
              path="/create-location"
              element={<CreateLocation />}
            />

            {/* Edit */}
            {/* <Route path="/edit-boat/:id" element={<CreateBoat />} /> */}
            <Route path="/edit-boat/:id" element={<EditBoat />} />
            <Route path="/edit-driver/:id" element={<EditDriver />} />
            <Route
              path="/edit-location/:id"
              element={<EditLocation />}
            />

            {/* Booking */}
            <Route
              path="/create-booking"
              element={<CreateBooking />}
            />

            <Route
              path="/create-cleaning/:type"
              element={<CreateBooking />}
            />

            <Route
              path="/create-booking/:id"
              element={<CreateBooking />}
            />

            <Route path="/booking/:id" element={<BookingView />} />

            <Route
              path="/driver-confirm"
              element={<DriverConfirm />}
            />

            {/* Driver */}
            <Route path="/driver-home" element={<DriverHome />} />
            <Route
              path="/driver-settings"
              element={<DriverSettings />}
            />
            <Route
              path="/driver-booking-view/:id"
              element={<DriverBookingView />}
            />
            <Route
              path="/driver-confirm-view"
              element={<ViewReservToAccept />}
            />

            <Route
              path="/alphafeatures/dragtest"
              element={<DragTest />}
            />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
