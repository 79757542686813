import { gql } from "@apollo/client";

export const GLOBALDATA_QUERY = gql`
  query {
    me {
      id
      firstName
      lastName
      email
      picture
      role
    }

    business(id: 2) {
      __typename
      id
      name
      address
      timeZone
      startTime
      endTime
      publishTime
      publishDaysBefore
    }

    resourcesByBusiness(businessId: 2) {
      id
      name
      picture
    }

    locationsByBusiness(businessId: 2) {
      id
      name
      picture
    }

    employees {
      id
      name
      code
      bgColor
      fgColor
      picture
    }
  }
`;

export const LOCATIONS_QUERY = gql`
  query {
    locationsByBusiness(businessId: 2) {
      id
      name
      picture
    }
  }
`;

export const RESOURCES_QUERY = gql`
  query {
    resourcesByBusiness(businessId: 2) {
      id
      name
      picture
      description
    }
  }
`;

export const EMPLOYEES_QUERY = gql`
  query {
    employees {
      id
      name
      code
      picture
      bgColor
      fgColor
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query ($id: Id) {
    employee(id: $id) {
      id
      name
      code
      bgColor
      fgColor
      picture
      updatedAt
      createdAt
    }
  }
`;

export const BOOKINGS_FILTERED_QUERY = gql`
  query ($locationId: Id, $date: String) {
    bookingsFiltered(locationId: $locationId, date: $date) {
      id
      clientName
      description
      startDate
      endDate
      pickup
      type
      location {
        id
        name
      }
      resource {
        id
        name
      }
      bookingEmployees {
        estado
        employee {
          id
          name
          bgColor
          fgColor
        }
      }
    }
  }
`;

export const BOOKINGS_EMPLOYEE_SCHEDULE_QUERY = gql`
  query ($businessId: Id!, $date: String) {
    bookingsEmployeeSchedule(businessId: $businessId, date: $date) {
      date
      employee {
        id
        name
        code
        picture
        bgColor
        fgColor
        updatedAt
        createdAt
      }
      estado
    }
  }

  query ($locationId: Id, $date: String) {
    bookingsEmployeeSchedule(locationId: $locationId, date: $date) {
      date
      employee {
        id
        name
        picture
      }
      estado
    }
  }
`;

export const BOOKING_BY_EMPLOYEE_QUERY = gql`
  query ($locationId: Id, $date: String) {
    bookingsByEmployee(locationId: $locationId, date: $date) {
      id
      clientName
      description
      startDate
      pickup
      endDate
      type
      location {
        id
        name
      }
      resource {
        id
        name
        picture
      }
      bookingEmployees {
        estado
        employee {
          id
          name
          picture
          bgColor
          fgColor
        }
      }
    }
  }
`;

export const BOOKING_COUNT_BY_DAY = gql`
  query ($businessId: Id!, $date: String!, $employeeId: Int) {
    bookingsCountByDay(
      businessId: $businessId
      date: $date
      employeeId: $employeeId
    )
  }
`;

export const BOOKING_BY_BUSINESS = gql`
  query ($businessId: Id!, $date: String) {
    bookingsByBusiness(businessId: $businessId, date: $date) {
      id
      startDate
      endDate
      bookingEmployees {
        employee {
          name
        }
        estado
      }
    }
  }
`;

export const BOOKING_BY_BUSINESS_COUNT = gql`
  query ($businessId: Id!, $date: String) {
    bookingsByBusiness(businessId: $businessId, date: $date) {
      id
      bookingEmployees {
        estado
        employee {
          id
          name
        }
      }
    }
  }
`;

export const BOOKING_QUERY = gql`
  query ($id: Id!) {
    booking(id: $id) {
      id
      clientName
      description
      startDate
      type
      pickup
      endDate
      type
      location {
        id
        name
      }
      resource {
        id
        name
        picture
      }
      bookingEmployees {
        estado
        employee {
          id
          name
          bgColor
          fgColor
        }
      }
    }
  }
`;

export const BOOKINGS_COUNT_BY_DAY = gql`
  query ($businessId: Id!, $date: String!, $employeeId: Int) {
    bookingsCountByDay(
      businessId: $businessId
      date: $date
      employeeId: $employeeId
    )
  }
`;

export const BOOKING_EMPLOYEE_SCHEDULE = gql`
  query ($businessId: Id!, $date: String) {
    bookingsEmployeeSchedule(businessId: $businessId, date: $date) {
      date
      employee {
        id
        name
        code
        picture
        bgColor
        fgColor
        updatedAt
        createdAt
      }
      estado
    }
  }
`;

export const GET_PUBLISH_TIME_ADMIN = gql`
  query ($id: Id!) {
    business(id: $id) {
      publishDaysBefore
      publishTime
    }
  }
`;

export const GET_COUNT_PER_DAY_EMPLOYEE = gql`
  query ($employeeId: Id!, $date: String!) {
    minutesPerDayOfDriver(employeeId: $employeeId, date: $date)
  }
`;
