import { useState, useEffect } from "react";
import { Slider, SliderThumb, SliderTrack } from "@chakra-ui/react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

import { Input } from "../../../ui";
import BackButton from "../../../components/BackButton";
import { toast } from "react-hot-toast";
import { useMutation, useQuery } from "@apollo/client";

import { UPDATE_PUBLISH_SETTINGS_MUTATION } from "../../../graphql/mutations";
import { useAuthContext } from "../../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { GET_PUBLISH_TIME_ADMIN } from "../../../graphql/querys";
import Spinner from "../../../components/Spinner";

export default function HourConfirm() {
  const navigate = useNavigate();
  const { globalData, refetchGlobalData } = useAuthContext();

  const { data: dataBusiness, loading: loadingBusiness } = useQuery(
    GET_PUBLISH_TIME_ADMIN,
    {
      variables: {
        id: globalData?.business.id,
      },
      fetchPolicy: "network-only",
    }
  );

  const [hourConfirm, setHourConfir] = useState<string>(
    dataBusiness?.business.publishTime
  );
  const [daysBefore, setDaysBefore] = useState<string>("");

  const [updatePublishSettings, { loading }] = useMutation(
    UPDATE_PUBLISH_SETTINGS_MUTATION
  );

  useEffect(() => {
    setHourConfir(dataBusiness?.business.publishTime);
  }, [globalData, loadingBusiness, dataBusiness]);

  useEffect(() => {
    console.log("initial render globalData", globalData);

    if (globalData?.business) {
      const { publishTime, publishDaysBefore, startTime } =
        globalData.business;

      console.log("publishTime", publishTime, startTime);

      const tiempo = `2023-04-22T${dataBusiness?.business.publishTime}`;
      const tiempoObj = dayjs(tiempo.slice(0, -1));

      // const tiempoS = `2023-04-22T${startTime}`;
      // const tiempoSObj = dayjs(tiempoS.slice(0, -1));

      const horasDiff = tiempoObj.hour();
      const minDiff = tiempoObj.minute();

      setOffset(horasDiff * 60 + minDiff); // Establece el offset aquí

      console.log({ horasDiff, minDiff });

      console.log("offset", horasDiff * 60 + minDiff); // Usa la expresión calculada directamente

      setDaysBefore(publishDaysBefore);
      setHourConfir(tiempoObj.format("hh:mm A"));

      setDaysBefore(publishDaysBefore);
      setHourConfir(tiempoObj.format("hh:mm A"));
      //  setHourConfir( globalData.business.publishTime)
    }
  }, [loadingBusiness, dataBusiness]);

  const handleSave = async () => {
    if (hourConfirm?.length <= 0 || daysBefore?.length <= 0) {
      return toast.error("All fields are required!");
    }

    const hours = getDayjsFromOffset(0, offset).format("hh");
    const minutes = getDayjsFromOffset(0, offset).format("mm");
    const prefix = getDayjsFromOffset(0, offset).format("a");

    const a2 =
      prefix === "am" && hours === "12"
        ? `00:${minutes} ${prefix}`
        : `${hours}:${minutes} ${prefix}`;

    updatePublishSettings({
      variables: {
        publishTime: a2,
        publishDaysBefore: daysBefore,
      },
    })
      .then((res) => {
        console.log("res", res);
        refetchGlobalData();
        toast.success("Posting Settings Updated!");
        navigate(-1);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const validationSubmit = () => {
    return hourConfirm?.length <= 0 || daysBefore?.length <= 0
      ? true
      : false;
  };

  const [option, setOption] = useState(0);

  const [timeStart, setTimeStart] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);

  const [duration, setDuration] = useState(2);

  const [offset, setOffset] = useState(-1);

  const getDayjsFromOffset = (initial: any, offset: any) => {
    return dayjs()
      .startOf("day")
      .add(initial, "hour")
      .add(offset, "minute");
  };

  const onChange = (d: any) => {
    setTimeStart(d[0]);
    setTimeEnd(d[1]);
  };

  return (
    <div className="flex flex-col justify-between h-screen">
      <div>
        <BackButton
          goBackRoute="/list-drivers"
          title="Schedule Settings"
        />

        <div className="flex items-center gap-[10px] px-5 mb-[10px]">
          <div className="w-full px-4">
            {loadingBusiness && (
              <div className="grid h-[65px] place-items-center">
                <p>Cargando..</p>
              </div>
            )}

            <div
              className={`${
                !loadingBusiness ? "flex" : "hidden"
              } justify-center mb-5 gap-5`}
            >
              <p className="text-[28px] font-bold text-darkBlue">
                {loadingBusiness ? (
                  <></>
                ) : (
                  getDayjsFromOffset(0, offset).format("hh:mm")
                )}
                <span className="ml-[5px] text-sm">
                  {getDayjsFromOffset(0, offset).format("A")}
                </span>
              </p>
            </div>

            {offset >= 0 && (
              <Slider
                defaultValue={offset}
                step={10}
                min={10}
                max={1430}
                onChange={(val) => {
                  setOffset(val);
                }}
                onChangeEnd={(val) => {
                  console.log(val, offset);

                  const from = dayjs()
                    .startOf("day")
                    .add(offset, "minute");

                  onChange({
                    value: from.format("hh:mm"),
                    prefix: from.format("a"),
                  });

                  setOffset(val);
                }}
              >
                <SliderTrack bg="vvas.lightPrimary"></SliderTrack>
                <SliderThumb
                  boxSize={8}
                  bgColor="vvas.darkYellow"
                ></SliderThumb>
              </Slider>
            )}
          </div>
        </div>

        <div className="flex items-center gap-[10px] px-5 mb-[25px]">
          <Input
            type="number"
            display="inline-block"
            w="70px"
            value={daysBefore}
            placeholder="??"
            onChange={(e: any) => setDaysBefore(e.target.value)}
            textAlign="center"
          />
          <p className="text-xl text-darkBlue">days before</p>
        </div>
      </div>

      <div className="p-[32px_20px]">
        <button
          className={`bg-darkBlue h-[54px] text-white rounded-md w-full text-center text-xl ${
            !!validationSubmit() ? "opacity-50" : "opacity-100"
          }`}
          disabled={!!validationSubmit()}
          onClick={handleSave}
        >
          {loading ? <Spinner /> : "Save changes"}
        </button>
      </div>
    </div>
  );
}
