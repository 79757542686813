export default function Spinner({ width = 20 }) {
  return (
    <div
      className='lds-ring'
      style={{ width, height: width }}
    >
      <div style={{ width, height: width }}></div>
      <div style={{ width, height: width }}></div>
      <div style={{ width, height: width }}></div>
      <div style={{ width, height: width }}></div>
    </div>
  );
}
