export default function ModalConfirmBooking({
  children,
  title,
  show,
  onClose,
}: any) {
  return (
    <div
      className={`${
        show ? "block" : "hidden"
      } fixed bottom-[30px] right-0 left-0 mx-auto z-[100] w-[90%] max-w-[480px] overflow-hidden shadow-[0px 0px 15px 0px rgba(0,0,0,0.1)] h-[250px]`}
    >
      <div
        className="bg-[rgba(0,0,0,0.6)] w-full h-full fixed top-0 left-0 z-[120px]"
        onClick={() => onClose(false)}
      />

      <div className="p-4 absolute left-0 bottom-0 bg-white w-full h-full z-[130] overflow-y-auto rounded-md">
        <div className="flex h-[40px] items-center justify-center">
          <p className="text-2xl font-bold text-darkBlue">
            {title || "Modal header"}
          </p>
        </div>
        <div className="mt-[15px]">{children}</div>
      </div>
    </div>
  );
}
