import {
  IoBoatSharp,
  IoDocumentTextSharp,
  IoLogOut,
  IoNotifications,
} from "react-icons/io5";
import { MdLocationPin } from "react-icons/md";
import { RiSteering2Fill } from "react-icons/ri";
import { useNavigate,   useLocation } from "react-router-dom";

import { useEffect } from "react";

import { useAuthContext } from "../providers/AuthProvider";

import LayoutAdmin from "../components/Layout/Admin";
import Button from "../components/Settings/Button";
import ButtonAdd from "../components/ButtonAdd";

export default function Settings() {
  const navigate = useNavigate();

  const { logout } = useAuthContext();


  const location = useLocation();

  useEffect(() => {
    console.log("location.state", location,  window.history)
  }, [location])




  return (
    <LayoutAdmin>
      <div className="p-5">
        <p className="text-2xl text-darkBlue text-center font-bold">
          Water Sport School
        </p>
      </div>

      <div className="grid gap-5 px-5 grid-cols-2">
        <Button
          onClick={() => navigate("/list-drivers")}
          icon={<RiSteering2Fill />}
          title="Drivers"
        />
        <Button
          onClick={() => navigate("/list-boats")}
          icon={<IoBoatSharp />}
          title="Boats"
        />
        <Button
          onClick={() => navigate("/list-locations")}
          icon={<MdLocationPin />}
          title="Locations"
        />

        <Button
          onClick={() => navigate("/notifications")}
          icon={<IoNotifications />}
          title="Notifications"
        />

        <a
          href="https://www.vvassoftware.com/"
          target="_blank"
          className="rounded-md w-full h-[160px] bg-lightBlue flex items-center justify-center flex-col place-items-center no-underline"
        >
          <span className="text-white text-7xl block">
            <IoDocumentTextSharp />
            {/* {icon} */}
          </span>
          <span className="text-xl text-white font-bold mt-[10px] block">
            Legal Stuff
          </span>
        </a>

        <Button
          onClick={() => logout()}
          icon={<IoLogOut />}
          title="Logout"
        />

        <ButtonAdd />

        <div className="absolute bottom-5 left-5">
          {import.meta.env.VITE_DEV_ENV === 'DEV' && (
            <p className="text-xs">
              v. {import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA}
            </p>
          )}
        </div>
      </div>
    </LayoutAdmin>
  );
}
