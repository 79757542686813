import { useContext } from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";

import { CurrentDateContext } from "../../context/CurrentDateContext";
import Calendar from "../Calendar";

interface IModalOptions {
  isOpen: boolean;
  onClose: (value: boolean) => void;
}

export default function ModalCalendar({
  isOpen,
  onClose,
}: IModalOptions) {
  const { currentDate, setCurrentDate } = useContext(
    CurrentDateContext
  );

  console.log("ModalCalendar RENDERING", { currentDate });

  return (
    <AlertDialog.Root open={isOpen}>
      <AlertDialog.Portal className="z-[500]">
        <AlertDialog.Overlay
          onClick={() => {
            console.log("hello");
            onClose(false);
          }}
          className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
        />
        <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Calendar
            selectedDay={currentDate}
            setSelectedDay={setCurrentDate}
          />
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
