import {
  IoDocumentTextSharp,
  IoLogOut,
  IoNotifications,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../../providers/AuthProvider";

import LayoutAdmin from "../../components/Layout/Driver";
import Button from "../../components/Settings/Button";

export default function DriverSettings() {
  const navigate = useNavigate();
  const { logout, globalData } = useAuthContext();

  return (
    <LayoutAdmin>
      <div className="p-5">
        <p className="text-2xl text-darkBlue text-center font-bold">
          {globalData?.business.name}
        </p>
      </div>

      <div className="grid gap-5 px-5 grid-cols-2">
        <a
          href="https://www.vvassoftware.com/"
          className="rounded-md w-full h-[160px] bg-lightBlue flex items-center justify-center flex-col no-underline"
          target="_blank"
        >
          <span className="text-white text-7xl block">
            <IoDocumentTextSharp />
          </span>
          <span className="text-xl text-white font-bold mt-[10px] block">
            Legal Stuff
          </span>
        </a>

        <Button
          onClick={() => navigate("/driver-notifications")}
          icon={<IoNotifications />}
          title="Notifications"
        />

        <Button
          onClick={() => logout()}
          icon={<IoLogOut />}
          title="Logout"
        />
      </div>
    </LayoutAdmin>
  );
}
