import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

import { LOCATIONS_QUERY } from "../../../graphql/querys";
import BackButton from "../../../components/BackButton";
import CardLocation from "../../../components/Settings/CardLocation";
import { LocationListLoader } from "../../../components/Loader";

export default function ListLocations() {
  const location = useLocation();
  const { data, loading, error, refetch } = useQuery(LOCATIONS_QUERY);

  useEffect(() => {
    refetch();
  }, [location]);

  const onDeleteHandle = (id: any) => {
    refetch();
  };

  if (loading) return <LocationListLoader />;
  if (error) return <p>Error...</p>;

  return (
    <div>
      <BackButton goBackRoute="/settings" title="Locations" />

      <div className="px-5 pb-8">
        {data.locationsByBusiness.map((location: any) => (
          <CardLocation
            onDelete={onDeleteHandle}
            edit={`/edit-location/${location.id}`}
            route="/create-location"
            pageIntern
            info={{
              id: location.id,
              name: location.name,
            }}
          />
        ))}
      </div>
    </div>
  );
}
