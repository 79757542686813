import { Button } from "../../ui";

interface IButtonSettings {
  title: string;
  onClick?: () => void;
  icon: any;
}

export default function ButtonSettings({
  title,
  icon,
  onClick,
}: IButtonSettings) {
  return (
    <Button
      className="justify-center items-center rounded-md w-full h-[160px] bg-lightBlue flex flex-col"
      onClick={onClick}
    >
      <span className="text-white text-7xl block">{icon}</span>
      <span className="text-xl text-white font-bold mt-[10px] block">
        {title}
      </span>
    </Button>
  );
}
