import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import SpongeIcon from "../../assets/icons/sponge-icon.svg";
import BoatIcon from "../../assets/icons/boat-icon.svg";

interface Driver {
  id: string;
  name: string;
  image: string;
}

interface Booking {
  id: string;
  clientName: string;
  startTime: string;
  endTime: string;
  drivers: Driver[];
  resource: {
    id: string;
    name: string;
    picture: string;
  };
  createdAt: string;
}

interface IDriverBooking {
  booking: any;
}

export default function DriverBooking({ booking }: IDriverBooking) {
  const navigate = useNavigate();

  return (
    <div
      className="grid grid-cols-[40px_3px_1fr] px-5 gap-6"
      onClick={() => navigate(`/driver-booking-view/${booking.id}`)}
    >
      <div className="flex self-center flex-col items-center text-borderLightBlue">
        <div className="mb-2">
          {booking.type === "CLEANING" ? (
            <img src={SpongeIcon} alt="" />
          ) : (
            <img src={BoatIcon} alt="" />
          )}
        </div>

        <p className="text-2xl font-semibold">
          {dayjs(booking.startDate).format("hh")}
          <span className="text-lg">
            {dayjs(booking.startDate).format(":mm")}
          </span>
        </p>
        <p className="font-semibold text-xl">
          {dayjs(booking.startDate).format("A")}
        </p>
      </div>

      <div className="w-full bg-borderLightBlue" />

      <div className="rounded-md border border-borderLightBlue">
        <div className="border-b border-borderLightBlue">
          <div className="flex items-center p-5 justify-between">
            <p className="text-darkBlue font-bold text-[22px] text-center leading-[1]">
              {dayjs(booking.startDate).format("hh:mm")} <br />
              <span className="text-lg">
                {dayjs(booking.startDate).format("a")}
              </span>
            </p>
            <p className="text-darkBlue font-bold text-xl lowercase">
              to
            </p>
            <p className="text-darkBlue font-bold text-[22px] text-center leading-[1]">
              {dayjs(booking.endDate).format("hh:mm")} <br />
              <span className="text-lg">
                {dayjs(booking.endDate).format("a")}
              </span>
            </p>
          </div>
        </div>

        <div className="p-[10px_20px]">
          <p>{booking.id}</p>

          <div className="flex items-cneter mb-[7px]">
            <div className="w-5 mr-[5px]">
              <img
                className="w-5"
                src="/icons/driver-user-icon.svg"
                alt=""
              />
            </div>
            <p className="flex-1">{booking.clientName}</p>
          </div>
          <div className="flex items-center mb-[7px]">
            <div className="w-5 mr-[5px]">
              <img
                className="w-5"
                src="/icons/driver-driver-icon.svg"
                alt=""
              />
            </div>
            <div className="flex-1">
              <p className="mr-[5px]">
                {booking.employees.map((i: any) => i).join(", ")}
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-5 mr-[5px]">
              <img
                className="w-5"
                src="/icons/driver-boat-icon.svg"
                alt=""
              />
            </div>
            <p className="flex-1">
              {!booking.resourceName
                ? "No boat"
                : booking?.resourceName}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
