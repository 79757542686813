import React, { createContext, useContext, useState, useEffect} from 'react';
import { useQuery, useMutation, useLazyQuery, useApolloClient } from "@apollo/client";
import { GLOBALDATA_QUERY } from "../graphql/querys";
import { UPDATE_ONESIGNAL_EXTERNAL_ID_MUTATION } from "../graphql/mutations";

// @ts-ignore

// @ts-ignore
interface AuthContextInterface {
  authenticated: boolean;
  employee: any; // o el tipo correspondiente a tu objeto employee
  globalData: any; //  
  login: ( token: string) => void;
  refetchGlobalData: () => void;
  loadingGlobalData:any;
  logout: () => void;
  playerId:any;
}


export const AuthContext = createContext<AuthContextInterface>({
  authenticated: false,
  employee: null,
  globalData: null,
  login: (token: string) => {},
  logout: () => {},
  refetchGlobalData: () => {},
  loadingGlobalData:false,
  playerId:null
});

export const useAuthContext = () => useContext(AuthContext);

const AuhProvider= (props:any) => { 

  const [ fetchGlobalData,  { data:dataMainQuery, loading:loadingGlobalData, error, refetch:refetchGlobalData } ] = useLazyQuery(GLOBALDATA_QUERY);
    const { children, protect } = props;

    const [ updateOneSignalExternalIdMutation ] = useMutation(UPDATE_ONESIGNAL_EXTERNAL_ID_MUTATION);

  const [playerId, setPlayerId] = useState(null);
  const [authenticated, setAuthenticated] = useState(false)
  const [employee, setEmployee] = useState(null)
  const [globalData, setGlobalData] = useState(null)
  

  const login = (token:string)=>{
    console.log("login set token", token)
    localStorage.setItem('accessToken', token)
    fetchGlobalData();
    // setAuthenticated(true)
  }

  const logout = () => {

    // @ts-ignore
    window.OneSignal.push(async function() {
      // @ts-ignore
      window.OneSignal.removeExternalUserId();
    });
  
    setAuthenticated(false)
    setEmployee(null)
    localStorage.removeItem('accessToken')
    window.location.assign('/');
}




  useEffect(() => {

     const token = localStorage.getItem('accessToken')
     console.log("token storage", token)
      if(token){
        fetchGlobalData();   
      }

  }, [])
  




  useEffect(() => {

    const token = localStorage.getItem('accessToken')
   console.log("token storage", token)


    // const res =  await apolloClient.query( {query:ME} );
    // console.log("res", res)
    // const {me} = res.data

   if(dataMainQuery){
      setAuthenticated(true)
      setGlobalData(dataMainQuery)
    }



  }, [dataMainQuery])
  





  


  useEffect(() => {

    console.log("globalData", globalData)

    // @ts-ignore
    if (globalData && window.OneSignal) {
   

      try{
      // @ts-ignore
      window.OneSignal.push(async function() {
        // Si el usuario ya está suscrito, esto devolverá el playerId
        // @ts-ignore
        const userId = await window.OneSignal.getUserId();
        if(userId){
          setPlayerId(userId);
          
          // @ts-ignore
          window.OneSignal.setExternalUserId(`${globalData.me.role}_${globalData.me.id}`).then((res:any)=>{
            console.log("setExternalUserId res", res)
            // alert(`set external_id ok ${JSON.stringify(res)}`)
          }).catch((err:any)=>{
            console.log("setExternalUserId err", err)
          //  alert(`error on set external_id ${err.toString()}`)
          })         
          //updateOneSignalExternalIdMutation({variables:{playerId:userId}})
        }else{
         // alert("no user id")
        }

        // Si aún no está suscrito, puede escuchar el evento 'subscriptionChange'
        // @ts-ignore
        window.OneSignal.on('subscriptionChange', async function(isSubscribed) {
          if (isSubscribed) {
            // Cuando el usuario se suscribe, obtén el nuevo playerId
            // @ts-ignore
            const newUserId = await window.OneSignal.getUserId();
            setPlayerId(newUserId);

//            console.log("set external id con globaldata me id", newUserId, globalData.me.id)
            // @ts-ignore
            window.OneSignal.setExternalUserId(`${globalData.me.role}_${globalData.me.id}`).then(res=>{
              console.log("setExternalUserId res", res)
             // alert(`set external_id ok ${JSON.stringify(res)}`)
            }).catch((err:any)=>{
             // alert(`error on set external_id ${err.toString()}`)
              console.log("setExternalUserId err", err)
            })

            //updateOneSignalExternalIdMutation({variables:{playerId:newUserId}})
          }
        });
      });
    }catch(err){
      console.log("error on onesignal in authProvider", err)
    }




    }else{
      // @ts-ignore
      if(!window.OneSignal)alert("onesignal not defined")
    }

  }, [globalData])
  




  return (
    <AuthContext.Provider
      value={{
        authenticated,
        employee,
        globalData,
        login,logout,
        refetchGlobalData,
        loadingGlobalData,
        playerId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuhProvider
