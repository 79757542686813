import { useRef, useState } from "react";
import { FormControl, Input } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import BackButton from "../../../components/BackButton";
import useStorage from "../../../hooks/useStorage";
import { UPDATE_RESOURCE } from "../../../graphql/mutations";
import { IUpdateBoat } from "../../../interfaces/boat.interface";
import Spinner from "../../../components/Spinner";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().max(30, "Character limit of 30"),
  picture: yup.string(),
  id: yup.string(),
  description: yup.string(),
});

export default function EditBoat() {
  const location = useLocation();

  const navigate = useNavigate();
  const refButtonImage = useRef<any>(null);

  const [isCreating] = useState<boolean>(false);
  const [uploading, uploadFileToAws] = useStorage();
  const [uploadUrlFile, setUploadUrlFile] = useState(
    location.state.info.picture
  );

  const [updateResource, { loading }] = useMutation(UPDATE_RESOURCE);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: location.state.info.id,
      name: location.state.info.name,
      picture: location.state.info.picture,
      description: location.state.info.description,
    },
  });

  const handleFile = (e: any) => {
    const files = e.target.files;

    if (files) {
      const file = files[0];

      uploadFileToAws(file)
        .then((res: any) => {
          console.log("RES ON UPLOAD FILE uploadFileToAws", res);
          setUploadUrlFile(res.url);
          setValue("picture", res.url);
        })
        .catch((err: any) => {
          console.log("ERROR ON UPLOAD FILE uploadFileToAws", err);
        });
    }
  };

  function onSubmit(values: IUpdateBoat) {
    updateResource({
      variables: {
        data: {
          ...values,
        },
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data?.updateResource.status === 200) {
          const notify = () =>
            toast.success("Boat Updated Successfully");
          notify();
          return navigate(-1);
        }

        const notify = () =>
          toast("A boat with that name already exists", {
            icon: "⚠️",
          });
        notify();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <BackButton goBackRoute="/list-boats" title="EDIT BOAT" />

      {/* @ts-ignore */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between px-5 h-[calc(100vh_-_88px_-_32px)]">
          <div>
            <FormControl isInvalid={!!errors.name}>
              <Input
                className="form-control-input"
                type="text"
                _focusWithin={{ borderColor: "vvas.darkBlue" }}
                {...register("name", {
                  required: "This is required",
                })}
              />
              <p className="text-danger text-sm mt-2">
                {/* @ts-ignore */}
                {errors.name && errors.name.message}
              </p>
            </FormControl>

            <div className="flex mt-5">
              {uploadUrlFile && (
                <div className="mr-5 w-[120px]">
                  <img
                    className="rounded-md w-full h-[120px] object-cover"
                    src={uploadUrlFile}
                    alt=""
                  />
                </div>
              )}

              <input
                ref={refButtonImage}
                type="file"
                style={{ display: "none" }}
                name="file"
                id="file"
                onChange={handleFile}
              />

              <button
                type="button"
                className="bg-transparent block border border-darkBlue w-[130px] h-[120px] text-darkBlue p-[10px] rounded-md font-medium"
                onClick={() => refButtonImage.current?.click()}
              >
                {uploadUrlFile ? "Change Image" : "Add Image"}
              </button>
            </div>
          </div>

          <button
            type="submit"
            className={`bg-darkBlue h-[54px] text-white rounded-md font-medium text-lg ${
              !getValues().picture || !getValues().name
                ? "opacity-50"
                : "opacity-100"
            }`}
            disabled={!getValues().picture || !getValues().name}
          >
            {!!isSubmitting || !!loading || !!uploading ? (
              <Spinner />
            ) : isCreating ? (
              "Saving.."
            ) : (
              "Save changes"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
