import { useState } from "react";
import { useMutation } from "@apollo/client";
import { SIGN_AWS_FILE } from "../graphql/mutations";


type UploadFileToAwsFn = (file: File) => Promise<{ res: Response; url: string }>;

const useStorage = ():[boolean, UploadFileToAwsFn] => {
  const [signAwsFileMutation] = useMutation(SIGN_AWS_FILE);
  const [uploading, setUploading] = useState(false);

  const uploadFileToAws:UploadFileToAwsFn = async (file: any) => {
    try {
      setUploading(true);
      const res = await signAwsFileMutation({
        variables: { mimeType: file.type },
      });
      console.log("RES ON SIGN FILE", res.data.signAwsFile);
      console.log("signedRes", res);

      const { signedRequest, url } = res.data.signAwsFile;

      return new Promise((resolve, reject) => {
        fetch(signedRequest, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read", // Agrega este encabezado para permitir acceso público al objeto subido
          },
        })
          .then((res) => {
            resolve({ res, url });
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            setUploading(false);
          });
      });
    } catch (e) {
      setUploading(false);
      console.log("ERROR ON SIGN FILE", e);
      throw e;
    }
  };

  return [uploading, uploadFileToAws];
};

export default useStorage;
