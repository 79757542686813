import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation ($data: SignInInput!) {
    signIn(data: $data) {
      message
      status
      token
    }
  }
`;

export const LOGIN_EMPLOYEE = gql`
  mutation ($accessCode: String!) {
    signInEmployee(accessCode: $accessCode) {
      message
      status
      token
    }
  }
`;

export const ME = gql`
  query {
    me {
      id
      firstName
      lastName
      email
      picture
      role
    }
  }
`;

export const CREATE_BOOKING_MUTATION = gql`
  mutation ($data: CreateBooking2Input!) {
    createBooking2(data: $data) {
      __typename
      message
      status
    }
  }
`;


export const EDIT_SCHEDULE_MUTATION = gql`
  mutation ($data: [EditScheduleInput]!, $dataAux: JSON) {
    editSchedule(data: $data, dataAux: $dataAux) {
      __typename
      message
      status
    }
  }
`;




export const CREATE_LOCATION_MUTATION = gql`
  mutation ($data: CreateLocationInput!) {
    createLocation(data: $data) {
      __typename
      message
      status
    }
  }
`;

export const CREATE_RESOURCE_MUTATION = gql`
  mutation ($data: CreateResourceInput!) {
    createResource(data: $data) {
      __typename
      message
      status
    }
  }
`;

export const CREATE_EMPLOYEE_MUTATION = gql`
  mutation ($data: CreateEmployeeInput!) {
    createEmployee(data: $data) {
      __typename
      message
      status
    }
  }
`;

export const SIGN_AWS_FILE = gql`
  mutation ($mimeType: String) {
    signAwsFile(mimeType: $mimeType) {
      __typename
      url
      signedRequest
    }
  }
`;

export const UPDATE_PUBLISH_SETTINGS_MUTATION = gql`
  mutation ($publishTime: String!, $publishDaysBefore: Int!) {
    updateBusinessPublishSettings(
      publishTime: $publishTime
      publishDaysBefore: $publishDaysBefore
    ) {
      __typename
      message
      status
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation ($data: UpdateEmployeeInput!) {
    updateEmployee(data: $data) {
      __typename
      message
      status
    }
  }
`;

export const UPDATE_RESOURCE = gql`
  mutation ($data: UpdateResourceInput!) {
    updateResource(data: $data) {
      __typename
      message
      status
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation ($data: UpdateLocationInput!) {
    updateLocation(data: $data) {
      __typename
      message
      status
    }
  }
`;

export const DELETE_LOCATION_MUTATION = gql`
  mutation ($id: Id!) {
    deleteLocation(id: $id) {
      __typename
      message
      status
    }
  }
`;

export const DELETE_RESOURCE_MUTATION = gql`
  mutation ($id: Id!) {
    deleteResource(id: $id) {
      __typename
      message
      status
    }
  }
`;

export const DELETE_EMPLOYEE_MUTATION = gql`
  mutation ($id: Id!) {
    deleteEmployee(id: $id) {
      __typename
      message
      status
    }
  }
`;

export const CONFIRM_BOOKING = gql`
  mutation ($bookingsId: [Int]!) {
    confirmBookings(bookingsId: $bookingsId) {
      message
      status
    }
  }
`;

export const REJECT_BOOKING = gql`
  mutation ($bookingsId: [Int]!) {
    rejectBookings(bookingsId: $bookingsId) {
      message
      status
    }
  }
`;







export const CONFIRM_BOOKINGS_SCHEDULE = gql`
  mutation ($date: String!) {
    confirmBookingsSchedule(date: $date) {
      message
      status
    }
  }
`;


export const REJECT_BOOKINGS_SCHEDULE = gql`
  mutation ($date: String!) {
    rejectBookingsSchedule(date: $date) {
      message
      status
    }
  }
`;






export const DELETE_BOOKING = gql`
  mutation deleteBooking ($id: Id!) {
    deleteBooking(id: $id) {
      status
      message
    }
  }
`;


export const UPDATE_ONESIGNAL_EXTERNAL_ID_MUTATION = gql`
  mutation ($playerId: String!) {
    updateOneSignalExternalId(playerId: $playerId) {
      message
      status
    }
  }
`;