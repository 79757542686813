import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BookingState {
  value: Booking[];
}

interface Booking {
  id: string;
  driver: string;
  start: string;
  end: string;
}

const initialState: BookingState = {
  value: [
    {
      id: "1",
      driver: "2",
      start: "08",
      end: "09",
    },
    {
      id: "2",
      driver: "1",
      start: "10",
      end: "12",
    },
    {
      id: "3",
      driver: "4",
      start: "08",
      end: "09",
    },
    {
      id: "4",
      driver: "3",
      start: "10",
      end: "11",
    },
    {
      id: "5",
      driver: "2",
      start: "16",
      end: "17",
    },
    {
      id: "6",
      driver: "4",
      start: "11",
      end: "12",
    },
    {
      id: "7",
      driver: "3",
      start: "9",
      end: "11",
    },
  ],
};

export const bookingSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    addBooking: (state, action: PayloadAction<Booking>) => {
      state.value.push(action.payload);
    },
    updateBooking: (state, action: PayloadAction<Booking>) => {
      
       state.value = state.value.map((booking) =>
        booking.id === action.payload.id ? action.payload : booking
      );
    },
  },
});

export const { addBooking, updateBooking } = bookingSlice.actions;
export default bookingSlice.reducer;
