import React from 'react';

import { Button } from "../ui";


const SubscribeButton = () => {
  const subscribe = () => {
    // @ts-ignore
    if (window.OneSignal) {
      // @ts-ignore
      window.OneSignal.push(function() {
        // Este método abrirá la solicitud de permiso nativa del navegador
        // @ts-ignore
        window.OneSignal.showNativePrompt();
      });
    }
  }

  return (
    <Button color="vvas.darkBlue" bgColor="white" border="1px solid" borderColor="vvas.darkBlue" onClick={subscribe}>
      Subscribe to notifications
    </Button>
  );
}

export default SubscribeButton;