import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  BOOKINGS_FILTERED_QUERY,
  GET_EMPLOYEE,
} from "../graphql/querys";
import { CurrentDateContext } from "../context/CurrentDateContext";
import { useAuthContext } from "../providers/AuthProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { DateTime } from "luxon";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function heightCalcSum(val: number) {
  switch (val) {
    case 1:
      return 3;

    case 1.5:
      return 6;

    case 2:
      return 9;

    case 2.5:
      return 12;

    case 3:
      return 15;

    case 3.5:
      return 18;

    case 4:
      return 21;

    case 4.5:
      return 24;

    case 5:
      return 27;

    case 5.5:
      return 30;

    case 6:
      return 33;

    case 6.5:
      return 36;

    case 7:
      return 39;

    case 7.5:
      return 42;

    case 8:
      return 45;

    case 8.5:
      return 48;

    case 9:
      return 51;

    case 9.5:
      return 54;

    case 10:
      return 57;

    case 10.5:
      return 60;

    case 11:
      return 63;

    case 11.5:
      return 66;

    case 12:
      return 69;

    default:
      return 0;
  }
}

const TimeCellItem: React.FC<any> = ({
  item,
  index,
  reservas,
  driverId,
}) => {
  const { data: driver } = useQuery(GET_EMPLOYEE, {
    variables: {
      id: driverId,
    },
  });

  const value = reservas?.bookingsFiltered.some(
    (booking: any) =>
      dayjs(booking.startDate)
        .tz("America/New_York")
        .format("hh:mm:ss") === item &&
      booking.bookingEmployees.find(
        (employee: any) => employee?.employee.id === driverId
      )
  );

  const valueOfBooking = reservas?.bookingsFiltered.find(
    (booking: any) =>
      dayjs(booking.startDate)
        .tz("America/New_York")
        .format("hh:mm:ss") === item &&
      booking.bookingEmployees.find(
        (employee: any) => employee?.employee.id === driverId
      )
  );

  const endDateValue = dayjs(valueOfBooking?.endDate);
  const startDateValue = dayjs(valueOfBooking?.startDate);

  const [heightWindow, setHeightWindow] = useState<number>(0);

  useEffect(() => {
    setHeightWindow(window.innerHeight);
  }, []);

  // padding-top: 6px;
  // driver-box: 26px;
  // driver padding-bottom: 4px

  // principal box 25px
  // gap 3 * 24 = 72px

  const principalBoxHeight = (heightWindow - 6 - 26 - 4 -72 - 6) / 24;
  
  const diffHours = endDateValue.diff(startDateValue, "hours");

  const heightBookingItem =
    principalBoxHeight * (diffHours / 0.5) +
    (Math.floor(diffHours / 0.5) - 1) * 3;

  const valueDriver = reservas?.bookingsFiltered.some(
    (booking: any) =>
      booking.bookingEmployees.some(
        (employee: any) => employee?.employee.id === driverId
      )
  );  

  return (
    <div className="relative">
      <div
        key={index}
        className={`rounded-sm overflow-visible relative grid p-1 items-center w-[78px] ${
          value && valueDriver
            ? ""
            : "bg-white border border-slate-200"
        }`}
        style={{ height: principalBoxHeight }}
      >
        <div
          style={
            value && valueDriver
              ? {
                  height: heightBookingItem,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 20,
                  borderRadius: "3px",
                  padding: 5,
                  width: "100%",
                  backgroundColor:
                    valueOfBooking.type === "CLEANING"
                      ? "#616161"
                      : driver?.employee.bgColor,
                }
              : {}
          }
        >
          {value && valueDriver && (
            <div className="text-white">
              <p className="font-bold text-[9px]">
                {(valueOfBooking &&
                  valueOfBooking?.resource &&
                  valueOfBooking?.resource.name) ||
                  "No boat"}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DriverColumn: React.FC<any> = ({
  driverId,
  reservas,
  allHours,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleGyroscope = (event: DeviceMotionEvent) => {
      if (event.rotationRate) {
        const rotationRateX = event.rotationRate.alpha ?? 0;
        const rotationRateY = event.rotationRate.beta ?? 0;
        const rotationRateZ = event.rotationRate.gamma ?? 0;

        if (
          Math.abs(rotationRateX) > 1000 ||
          Math.abs(rotationRateY) > 1000 ||
          Math.abs(rotationRateZ) > 1000
        ) {
          navigate("/");
        }
      }
    };

    if (typeof window !== "undefined" && window.DeviceMotionEvent) {
      window.addEventListener("devicemotion", handleGyroscope, false);
    }

    return () => {
      if (typeof window !== "undefined" && window.DeviceMotionEvent) {
        window.removeEventListener(
          "devicemotion",
          handleGyroscope,
          false
        );
      }
    };
  }, []);

  const { data: driver } = useQuery(GET_EMPLOYEE, {
    variables: {
      id: driverId,
    },
  });

  if (!driver?.employee) return null;

  return (
    <div>
      <div>
        <div
          className={`w-[78px] text-xs bg-white border border-l-4 rounded-sm h-[26px] flex items-center pl-2 uppercase text-red-500`}
          style={{
            borderColor: driver?.employee.bgColor,
            color: driver?.employee.bgColor,
          }}
        >
          {driver?.employee.name.split(" ")[0]}
        </div>
        <div className="mt-1 grid gap-y-[3px] h-full">
          {allHours.map((item: any, index: number) => (
            <TimeCellItem
              driverId={driverId}
              key={index}
              reservas={reservas}
              item={item}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default function MacroView() {
  const navigate = useNavigate();

  const allDriversSet = new Set();
  const allHours: any = [];

  // TODO: global loading data
  const { globalData, loadingGlobalData } = useAuthContext();

  const startTime = `${+globalData?.business.startTime.slice(
    0,
    2
  )}:00:00`;
  const endTime = `${+globalData?.business.endTime.slice(
    0,
    2
  )}:00:00`;

  const openingTimeParsed = dayjs(startTime, "hh:mm:ss");
  const closingTimeParsed = dayjs(endTime, "hh:mm:ss");

  let currentHour = openingTimeParsed;
  while (currentHour.isBefore(closingTimeParsed)) {
    allHours.push(currentHour.format("hh:mm:ss"));
    currentHour = currentHour.add(30, "minutes");
  }

  const { currentDate } = useContext(CurrentDateContext);

  const horaBase = dayjs(
    DateTime.fromFormat(
      `${dayjs(currentDate).format(
        "YYYY-MM-DD"
      )} ${globalData.business.startTime.substr(0, 5)}`,
      "yyyy-MM-dd hh:mm"
    ).toISO()
  );
  
  const { data: reservas, loading: loadingReservas } = useQuery(
    BOOKINGS_FILTERED_QUERY,
    {
      variables: {
        locationId: null,
        date: horaBase.toDate(),
      },
      fetchPolicy: "network-only",
    }
  );

  reservas?.bookingsFiltered.forEach((booking: any) => {
    booking.bookingEmployees.forEach((bookingEmployee: any) => {
      allDriversSet.add(bookingEmployee.employee.id);
    });
  });

  const allDrivers = Array.from(allDriversSet);

  const [heightWindow, setHeightWindow] = useState<number>(0);

  useEffect(() => {
    setHeightWindow(window.innerHeight);
  }, []);

  const principalBoxHeight =
    (heightWindow - 6 - 26 - 4 - 72 - 6) / 24;

  const heightBookingItem = principalBoxHeight;
  console.log({ heightBookingItem });
  

  if (loadingGlobalData || loadingReservas) {
    return (
      <div className="fixed top-0 grid place-items-center left-0 w-screen h-screen">
        <p className="text-xl">Loading..</p>
      </div>
    );
  }

  let allDriversFiltered = allDrivers?.sort(
    (a: any, b: any) => a - b
  );

  if (allDrivers?.length === 0 || !allDrivers) {
    return (
      <div className="fixed top-0 grid place-items-center left-0 w-screen h-screen">
        <div>
          <p className="text-xl">
            There are no reservations on the day
          </p>
          <div className="flex justify-center">
            <button
              onClick={() => navigate(-1)}
              className="text-lg mt-3 bg-darkBlue px-8 py-3 rounded-md text-white"
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-[6px] h-full ml-6 overflow-x-scroll w-max">
      <div className="h-full w-[20px] shadow-lg z-50 bg-white fixed left-0 top-0">
        <div className="h-[26px] mt-1" />

        <div className="grid gap-y-[3px] justify-center mt-[3px]">
          {allHours?.map((hour: any) => (
            <div
              className={`text-[10px] font-bold text-darkBlue `}
              key={hour}
              style={
                hour.includes("30")
                  ? { opacity: 0, height: heightBookingItem }
                  : { opacity: 1, height: heightBookingItem }
              }
            >
              {hour.slice(0, 2)}
            </div>
          ))}
        </div>
      </div>

      <div className="flex gap-1">
        {allDriversFiltered?.map((driverId: any) => (
          <DriverColumn
            allHours={allHours}
            key={driverId}
            reservas={reservas}
            driverId={driverId}
          />
        ))}
        <div className="w-[6px] bg-white h-20"></div>
      </div>
    </div>
  );
}
