export default function DriverListLoader() {
  return (
    <div>
      <div className="flex p-5 items-center">
        <div className="mr-[15px] bg-gray-300 w-12 h-12 rounded-sm" />
        <div className="w-[150px] h-[15px] bg-gray-400 rounded-sm" />
      </div>

      <div className="px-5 mb-5">
        <div className="w-full h-[50px] rounded-md bg-gray-300" />
      </div>

      <div className="grid px-5 gap-5 grid-cols-2">
        <div className="w-full h-[160px] bg-gray-400 rounded-sm" />
        <div className="w-full h-[160px] bg-gray-400 rounded-sm" />
        <div className="w-full h-[160px] bg-gray-400 rounded-sm" />
        <div className="w-full h-[160px] bg-gray-400 rounded-sm" />
      </div>
    </div>
  );
}
