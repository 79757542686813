import { useEffect } from "react";
import { BsFillCheckSquareFill } from "react-icons/bs";

import BackButton from "../../components/BackButton";
import OneSignalSubscribeButton from "../../components/OneSignalSubscribeButton";
import { useAuthContext } from "../../providers/AuthProvider";

export default function Notifications() {
  const { globalData } = useAuthContext();
  const { playerId } = useAuthContext();

  //   useEffect(() => {
  //     if (window.OneSignal) {
  //       window.OneSignal.push(async function() {
  //         // Verifica si las notificaciones push están habilitadas
  //         const enabled = await window.OneSignal.isPushNotificationsEnabled();
  //         setIsSubscribed(enabled);
  //       });
  //     }
  //   }, []);

  useEffect(() => {
    // if (window.OneSignal) {
    //   window.OneSignal.push(async function() {
    //     // Verifica si las notificaciones push están habilitadas
    //     const enabled = await window.OneSignal.isPushNotificationsEnabled();
    //     setIsSubscribed(enabled);
    //   });
    // }
    // if (window.OneSignal) {
    //     window.OneSignal.push(async function() {
    //       // Si el usuario ya está suscrito, esto devolverá el playerId
    //       const userId = await window.OneSignal.getUserId();
    //       setPlayerId(userId);
    //       // Si aún no está suscrito, puede escuchar el evento 'subscriptionChange'
    //       window.OneSignal.on('subscriptionChange', async function(isSubscribed) {
    //         if (isSubscribed) {
    //           // Cuando el usuario se suscribe, obtén el nuevo playerId
    //           setIsSubscribed(true);
    //           const newUserId = await window.OneSignal.getUserId();
    //           setPlayerId(newUserId);
    //         }
    //       });
    //     });
    //   }
  }, []);

  return (
    <div>
      <BackButton
        goBackRoute={
          globalData?.me.role === "DRIVER"
            ? "/driver-settings"
            : "/settings"
        }
        title="Notifications"
      />
      <div className="px-5">
        {playerId ? (
          <div className="mx-5 p-[15px] shadow-lg rounded-md border border-[#f2f2f2]">
            <div className="flex items-center justify-between">
              <p className="text-darkBlue uppercase font-bold">
                NOTIFICATIONS ARE TURNED ON
              </p>
              <p className="ml-[10px] text-darkBlue text-2xl">
                <BsFillCheckSquareFill />
              </p>
            </div>
            <div className="mt-[5px] text-sm text-borderLightBlue">
              {playerId}
            </div>
          </div>
        ) : (
          <OneSignalSubscribeButton />
        )}
      </div>
    </div>
  );
}
