import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-hot-toast";

import BackButton from "../components/BackButton";
import Spinner from "../components/Spinner";
import { LOGIN } from "../graphql/mutations";
import { useAuthContext } from "../providers/AuthProvider";
import { Button } from "../ui";
import "../styles/tailwind.css";


export default function LoginQuick() {
  const { login, refetchGlobalData } = useAuthContext();
  const navigate = useNavigate();

  const [sending, setSending] = useState<boolean>(false);
  const [loginInfo, setLoginInfo] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  const [loginMutation, { loading: loginLoading }] =
    useMutation(LOGIN);

  const onSubmit = (e: any) => {
    e.preventDefault();
    setSending(true);

    if (!loginInfo.email || !loginInfo.password) {
      toast.error("Fields are required");
      return;
    };

    loginMutation({
      variables: {
        data: {
          ...loginInfo,
        },
      },
    })
      .then((res) => {
        login(res?.data.signIn.token);

        if (
          res?.data.signIn.message === "invalid password" ||
          res?.data.signIn.message === "user not found"
        ) {
          toast.error("Incorrect Credentials");
          return;
        }

        refetchGlobalData();
        toast.success("You are logged in");
        navigate("/");
      })
      .catch((error) => {
        toast.error("Error");
      });
  };

  return (
    <>
      <BackButton goBackRoute="/auth" title="" />

      <div className="p-[0_20px_30px_20px]">
        <p className="uppercase text-darkBlue text-3xl font-black text-center">
          ACCESS YOUR ACCOUNT
        </p>

        <p className="text-darkBlue text-center mt-2">
          Enter your information below and access your VVAS account to
          manage your business.
        </p>

        <div>
          <div className="mt-8">
            <form onSubmit={onSubmit}>
              <label
                className="mb-2 text-sm font-semibold block text-darkBlue"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="form-input"
                style={
                  sending && !loginInfo.email
                    ? { borderColor: "red" }
                    : {}
                }
                type="email"
                placeholder="Email"
                value={loginInfo.email}
                onChange={(e) =>
                  setLoginInfo({
                    ...loginInfo,
                    email: e.target.value,
                  })
                }
              />

              <div className="mt-4 mb-6">
                <label
                  className="text-sm font-semibold block mb-2 text-darkBlue"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="form-input"
                  style={
                    sending && !loginInfo.password
                      ? { borderColor: "red" }
                      : {}
                  }
                  type="password"
                  placeholder="********"
                  value={loginInfo.password}
                  onChange={(e) =>
                    setLoginInfo({
                      ...loginInfo,
                      password: e.target.value,
                    })
                  }
                />
              </div>

              <div>
                <Button w="full" type="submit">
                  {loginLoading ? <Spinner /> : "Sign In"}
                </Button>
              </div>
            </form>
            <p className="mt-4 text-center text-gray-500 text-xs">
              &copy;2023 VVAS
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
