export default function LocationListLoader() {
  return (
    <div>
      <div className="flex p-5 items-center">
        <div className="mr-[15px] bg-gray-300 w-12 h-12 rounded-sm" />
        <div className="w-[150px] h-[15px] bg-gray-400 rounded-sm" />
      </div>

      <div className="px-5">
        <div className="w-full h-[55px] mb-[10px] bg-gray-400 rounded-sm" />
        <div className="w-full h-[55px] mb-[10px] bg-gray-400 rounded-sm" />
        <div className="w-full h-[55px] mb-[10px] bg-gray-400 rounded-sm" />
      </div>
    </div>
  );
}
