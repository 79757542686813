import dayjs from "dayjs";

export const getHourMinute = (offset: number) => {
  const date = dayjs().hour(6).minute(0).second(0);
  return date.add(offset * 30, "minute").format("hh:mm A");
};

export const getDayjsFromOffset = (initial: any, offset: any) => {
  return dayjs()
    .startOf("day")
    .add(initial, "minute")
    .add(offset * 30, "minute");
};

export const getTimeWithPrefixFromOffset = (offset: number) => {
  const date = dayjs().hour(6).minute(0).second(0);

  console.log("date", offset, date.format());

  const base = date.add(offset * 30, "minute");

  console.log("base", base.format());

  return {
    value: base.format("hh:mm"),
    prefix: base.format("A"),
  };
};
