import { useEffect, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";

dayjs.extend(duration);

import { Modal } from "../ui";

export default function SelectTime2({
  show,
  onClose,
  onChange,
  offset1,
  offsetDuration,
}: any) {
  const [offset, setOffset] = useState(offset1);
  const [offset2, setOffset2] = useState(offsetDuration);

  useEffect(() => {
    setOffset(offset1);
    setOffset2(offsetDuration);
  }, [offset1, offsetDuration]);

  console.log({ offset, offset2 });

  const getDayjsFromOffset = (initial: any, offset: any) => {
    return dayjs()
      .startOf("day")
      .add(initial, "minute")
      .add(offset * 30, "minute");
  };

  return (
    <Modal
      title="Select a time"
      show={show}
      onClose={onClose}
      height={164}
    >
      <div className="px-4">
        <div className="flex justify-center mb-5 gap-5">
          <p className="text-[28px] font-bold text-darkBlue">
            {getDayjsFromOffset(60 * 6, offset).format("hh:mm")}
            <span className="ml-5 text-sm font-normal">
              {getDayjsFromOffset(60 * 6, offset).format("A")}
            </span>
          </p>

          <p className="text-[28px] font-bold text-darkBlue">
            {getDayjsFromOffset(60 * 6, offset2).format("hh:mm")}
            <span className="ml-[5px] text-sm font-normal">
              {getDayjsFromOffset(60 * 6, offset2).format("A")}
            </span>
          </p>
        </div>
        <RangeSlider
          aria-label={["min", "max"]}
          value={[offset, offset2]}
          min={0}
          max={24}
          minStepsBetweenThumbs={2}
          onChange={(val) => {
            setOffset(val[0]);
            setOffset2(val[1]);
          }}
          onChangeEnd={(val) => {
            console.log(val, offset, offset2);

            const from = getDayjsFromOffset(60 * 6, offset);
            const to = getDayjsFromOffset(60 * 6, offset2);

            onChange([
              {
                value: from.format("hh:mm"),
                prefix: from.format("a"),
              },
              { value: to.format("hh:mm"), prefix: to.format("a") },
            ]);

            setOffset(val[0]);
            setOffset2(val[1]);
          }}
        >
          <RangeSliderTrack bg="vvas.lightPrimary">
            <RangeSliderFilledTrack bg="vvas.darkBlue" />
          </RangeSliderTrack>
          <RangeSliderThumb
            boxSize={8}
            index={0}
            bgColor="vvas.darkYellow"
          ></RangeSliderThumb>
          <RangeSliderThumb
            boxSize={8}
            index={1}
            bgColor="vvas.darkYellow"
          ></RangeSliderThumb>
        </RangeSlider>
        <div className="mt-4 flex items-center justify-center gap-[3px]">
          <p className="text-center text-lg text-darkBlue">Total:</p>
          <span className="font-bold text-xl text-darkBlue">
            {Number(
              dayjs(
                getDayjsFromOffset(60 * 6, offset2).format()
              ).diff(
                getDayjsFromOffset(60 * 6, offset).format(),
                "minutes"
              )
            ) / 60}{" "}
            <span className="text-center text-lg text-darkBlue font-normal">
              hours
            </span>
          </span>
        </div>
      </div>
    </Modal>
  );
}
