export const BOATS = [
  {
    id: "1",
    image:
      "https://images.pexels.com/photos/4934614/pexels-photo-4934614.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Nautique S23",
  },
  {
    id: "2",
    image:
      "https://images.pexels.com/photos/4934630/pexels-photo-4934630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Nautique G21",
  },
  {
    id: "3",
    image:
      "https://images.pexels.com/photos/144634/pexels-photo-144634.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Air Nautique",
  },
  {
    id: "4",
    image:
      "https://images.pexels.com/photos/1830931/pexels-photo-1830931.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Ski Nautique",
  },
];
