import { useState } from "react";

import BackButton from "../components/BackButton";
import DriverBooking from "../components/Driver/Booking";
import ModalConfirm from "../components/Driver/ModalConfirm";
import { Button } from "../ui";

interface Driver {
  id: string;
  name: string;
  image: string;
}

interface Booking {
  id: string;
  clientName: string;
  startTime: string;
  endTime: string;
  drivers: Driver[];
  resource: {
    id: string;
    name: string;
    image: string;
  };
  createdAt: string;
}

const reservations = [
  {
    id: "1",
    clientName: "Santiago Wasmosy",
    startTime: "07am",
    endTime: "08am",
    drivers: [
      {
        id: "1",
        name: "Tom Collins",
        image: "https://bit.ly/43cFGQF",
      },
      {
        id: "2",
        name: "Harry L",
        image: "https://bit.ly/43cFGQF",
      },
    ],
    resource: {
      id: "1",
      image: "https://bit.ly/43e6jVm",
      name: "Nautique S23",
    },
    createdAt: "2011-10-05T14:48:00.000Z",
  },
];

export default function ViewReservToAccept() {
  const [showModalConfirm, setShowModalConfirm] =
    useState<boolean>(false);

  return (
    <div>
      <BackButton goBackRoute="/driver-home" title="April 4" />

      <ModalConfirm
        title="Confirm your schedule"
        show={showModalConfirm}
        onClose={setShowModalConfirm}
        height="max-content"
      >
        <p className="text-darkBlue">
          If you can confirm your schedule, click yes, otherwise click
          no
        </p>

        <div className="grid grid-cols-2 py-8 gap-[15px]">
          <Button onClick={() => setShowModalConfirm(true)}>
            Confirm
          </Button>
          <Button
            onClick={() => setShowModalConfirm(false)}
            bgColor="vvas.lightBlue"
            color="vvas.darkBlue"
          >
            Close
          </Button>
        </div>
      </ModalConfirm>

      <div>
        {reservations.map((reservation: Booking) => (
          <DriverBooking key={reservation.id} booking={reservation} />
        ))}
      </div>

      <div className="grid px-5 py-8 grid-cols-2 gap-[15px]">
        <Button onClick={() => setShowModalConfirm(true)}>
          Confirm
        </Button>
        <Button bgColor="vvas.lightBlue" color="vvas.darkBlue">
          Rejected
        </Button>
      </div>
    </div>
  );
}
