// import Navbar from "./Navbar";

export default function LayoutAdmin({ children, ...rest }: any) {
  return (
    <div className="max-w-[820px] mx-auto  h-full  select-none" {...rest}>
      {children}

      {/* <Navbar /> */}
    </div>
  );
}
