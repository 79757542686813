import { useRef, useState } from "react";
import { FormControl, Input } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { CREATE_EMPLOYEE_MUTATION } from "../../../graphql/mutations";
import useStorage from "../../../hooks/useStorage";

import BackButton from "../../../components/BackButton";
import { ICreateDriver } from "../../../interfaces/driver.interface";
import Spinner from "../../../components/Spinner";

export default function CreateDriver() {
  const refButtonImage = useRef<any>(null);

  const [uploadUrlFile, setUploadUrlFile] = useState(null);
  const [uploading, uploadFileToAws] = useStorage();

  const [createEmployeeMutation, { loading }] = useMutation(
    CREATE_EMPLOYEE_MUTATION
  );
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: "",
      picture: "",
    },
  });

  function onSubmit(values: ICreateDriver) {
    createEmployeeMutation({
      variables: {
        data: {
          ...values,
        },
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data?.createEmployee.status === 200) {
          const notify = () =>
            toast.success("Driver created successfully");
          notify();
          return navigate(-1);
        }

        const notify = () =>
          toast("A driver with that name already exists", {
            icon: "⚠️",
          });
        notify();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleFile = (e: any) => {
    const files = e.target.files;

    if (files) {
      const file = files[0];

      uploadFileToAws(file)
        .then((res: any) => {
          console.log("RES ON UPLOAD FILE uploadFileToAws", res);
          setUploadUrlFile(res.url);
          setValue("picture", res.url);
        })
        .catch((err: any) => {
          console.log("ERROR ON UPLOAD FILE uploadFileToAws", err);
        });
    }
  };

  return (
    <div>
      <BackButton goBackRoute="/list-drivers" title="ADD A DRIVER" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex h-[calc(100vh_-_88px_-_32px)] flex-col justify-between px-5">
          <div>
            <FormControl isInvalid={!!errors.name}>
              <Input
                className="form-control-input"
                textTransform="capitalize"
                type="text"
                id="name"
                placeholder="Driver's name"
                {...register("name", {
                  required: "This is required",
                  maxLength: {
                    value: 16,
                    message: "The maximum number of characters is 16",
                  },
                })}
              />
              <p className="text-danger text-sm mt-2">
                {errors.name && errors.name.message}
              </p>
            </FormControl>

            <div className="flex mt-5">
              {uploadUrlFile && (
                <div className="mr-5 w-[120px] relative rounded-md overflow-hidden">
                  <div className="w-full h-full bg-neutral-900/40 absolute top-0 left-0 z-50" />

                  <img
                    className="rounded-md w-full h-[120px] object-cover"
                    src={uploadUrlFile}
                    alt=""
                  />
                </div>
              )}

              <input
                ref={refButtonImage}
                type="file"
                style={{ display: "none" }}
                name="file"
                id="file"
                onChange={handleFile}
              />

              <button
                type="button"
                className="border border-darkBlue w-[130px] rounded-md text-darkBlue h-[120px]"
                onClick={() => refButtonImage.current?.click()}
              >
                {uploadUrlFile ? "Change Image" : "Add Image"}
              </button>
            </div>
          </div>

          <button
            type="submit"
            className={`bg-darkBlue h-[54px] text-white rounded-md font-medium text-lg ${
              !getValues().picture ? "opacity-50" : "opacity-100"
            }`}
            disabled={!getValues().picture}
          >
            {!!isSubmitting || !!loading || !!uploading ? (
              <Spinner />
            ) : (
              "Add Driver"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
