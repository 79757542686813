import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as AlertDialog from "@radix-ui/react-alert-dialog";

import BackButton from "../../components/BackButton";

function ItemDriver() {
  return (
    <div className="rounded-md overflow-hidden relative w-[150px]">
      <div className="absolute z-1 w-full h-full bg-[rgba(0,0,0,0.2)]" />

      <img
        className="w-full h-[150px]"
        src="https://bit.ly/3Z6m1yR"
        alt=""
      />
      <p className="absolute bottom-[10px] left-[10px] font-semibold text-white uppercase z-[60]">
        Jack Garner
      </p>
    </div>
  );
}

export default function BookingView() {
  const navigate = useNavigate();

  const [isOpenCancelReservation, setIsOpenCancelReservation] =
    useState<boolean>(false);

  const onOpenCancelReservation = () =>
    setIsOpenCancelReservation(true);
  const onCloseCancelReservation = () =>
    setIsOpenCancelReservation(false);

  return (
    <div>
      <div className="relative z-20">
        <BackButton
          goBackRoute="/"
          color="white"
          title="Reservation details"
        />
      </div>

      <AlertDialog.Root open={isOpenCancelReservation}>
        <AlertDialog.Portal className="z-[500]">
          <AlertDialog.Overlay
            onClick={() => {
              console.log("hello");
              onCloseCancelReservation();
            }}
            className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
          />
          <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <header className="font-bold text-darkBlue text-2xl">
              Do you want to cancel?
            </header>

            <main>
              <p>Are you sure you want to cancel this reservation?</p>
            </main>

            <footer>
              <button className="w-full text-white uppercase bg-darkBlue p-[14px_0] h-auto rounded-md mr-[10px]">
                Cancel
              </button>
              <button
                className="w-full text-darkBlue uppercase bg-lightBlue p-[14px_0] h-auto rounded-md"
                onClick={onCloseCancelReservation}
              >
                Close
              </button>
            </footer>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>

      <div className="-z-[1] -mt-[90px] bg-[url('https://bit.ly/3JwYpgY')] bg-cover bg-center h-[390px] w-full max-w-[480px] mx-auto relative">
        <div className="flex z-40 absolute bottom-[60px] right-5 flex-col items-end">
          <p className="text-[28px] text-white uppercase font-bold">
            Nautique s23
          </p>
          <p className="uppercase text-white text-lg">
            November 23th, 2023
          </p>
          <p className="font-bold text-white text-lg uppercase">
            02pm to 04pm
          </p>
        </div>
        <div className="w-full h-[390px] z-[1] absolute top-0 left-0 bg-[rgba(0,0,0,0.5)]" />
      </div>

      <div className="-mt-12 bg-white rounded-tl-3xl rounded-tr-3xl p-5 relative z-20">
        <div className="bg-darkBlue p-[15px_20px] rounded-md">
          <p className="text-[28px] text-white font-bold">
            John Palmer
          </p>
          <p className="uppercase text-white text-sm">
            Winter Garden, Red Dock
          </p>
        </div>
      </div>

      <div className="bg-white px-5">
        <p className="font-bold text-darkBlue uppercase text-lg mb-[10px]">
          Driver
        </p>

        <div className="flex flex-wrap gap-[15px]">
          <ItemDriver />
          <ItemDriver />
        </div>
      </div>

      <div className="p-5 pb-0">
        <p className="">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Pellentesque ullamcorper orci sit amet accumsan
          sollicitudin. Duis lobortis nisi vitae velit mollis aliquet.
          Curabitur condimentum purus felis. In non vehicula nibh, et
          facilisis nulla. Mauris massa tortor, malesuada ac
          sollicitudin quis, viverra quis dolor. Phasellus hendrerit
          ut urna eu dictum. Nunc quis nulla suscipit mauris pretium
          blandit
        </p>
      </div>

      <div className="p-[32px_20px] gap-[15px]">
        <button
          className="font-semibold uppercase"
          onClick={() => navigate("/edit-booking/1")}
        >
          Edit reservation
        </button>
        <button
          className="font-semibold uppercase text-darkBlue bg-lightBlue"
          onClick={onOpenCancelReservation}
        >
          Cancel reservation
        </button>
      </div>
    </div>
  );
}
