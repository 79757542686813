import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import * as AlertDialog from "@radix-ui/react-alert-dialog";

import {
  DELETE_EMPLOYEE_MUTATION,
  DELETE_RESOURCE_MUTATION,
} from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import Spinner from "../Spinner";
import { toast } from "react-hot-toast";

interface ICardEdit {
  edit: string;
  pageIntern: boolean;
  route: string;
  type: string;
  onDelete: (id: any) => void;
  refetch?: () => void;
  info: {
    id: string;
    name: string;
    color?: string;
    picture: string;
    code?: string;
    description?: string;
  };
}

export default function CardEdit({
  edit,
  info,
  type,
  refetch,
}: ICardEdit) {
  const navigate = useNavigate();
  const [deleteResource, { loading: loadingResource }] = useMutation(
    DELETE_RESOURCE_MUTATION
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const [deleteEmployee, { loading: loadingEmployee }] = useMutation(
    DELETE_EMPLOYEE_MUTATION
  );

  const handleDeleteResource = () => {
    deleteResource({
      variables: {
        id: info.id,
      },
    })
      .then((r) => {
        console.log(r);
        toast.success(`${info.name} was deleted`);
        // @ts-ignore
        refetch();
        onClose();
      })
      .catch((e) => {
        toast.error("There was a problem deleting. Try again");
        console.log("error", e);
      });
  };

  const handleDeleteEmployee = () => {
    deleteEmployee({
      variables: {
        id: info.id,
      },
    })
      .then((r) => {
        console.log(r);
        // @ts-ignore
        refetch();
        toast.success(`${info.name} was deleted`);
        onClose();
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const handleDelete = () => {
    switch (type) {
      case "boat":
        handleDeleteResource();
        break;

      case "driver":
        handleDeleteEmployee();
        break;
    }
  };

  return (
    <div className="w-full h-[160px] relative rounded-md overflow-hidden">
      <div className="w-full h-full bg-neutral-900/40 absolute top-0 left-0 z-50" />
      
      <AlertDialog.Root open={isOpen}>
        <AlertDialog.Portal className="z-[500]">
          <AlertDialog.Overlay
            onClick={() => {
              console.log("hello");
              onClose();
            }}
            className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
          />
          <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-1/2 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] -translate-y-1/2 rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <header className="mb-3">
              <p className="font-bold text-darkBlue text-2xl leading-[1.1]">
                Do you want to delete {type === "driver" ? "" : "the"}{" "}
                {info.name}?
              </p>
            </header>

            <main className="my-5">
              <p>This would permanently delete this {type}</p>
            </main>

            <div className="flex items-center gap-3">
              <button
                className="w-full text-white uppercase font-medium bg-darkBlue py-[14] h-[52px] rounded-md mr-2"
                onClick={handleDelete}
              >
                {loadingEmployee || loadingResource ? (
                  <Spinner />
                ) : (
                  "Delete"
                )}
              </button>
              <button
                className="w-full text-darkBlue uppercase font-medium bg-lightBlue py-[14] h-[52px] rounded-md mr-2"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>

      {/* overlay */}

      <div
        className={`absolute top-[10px] left-[10px] w-[25px] h-[25px] rounded-full z-50`}
        style={{ backgroundColor: info.color }}
      ></div>

      <div className="absolute top-0 left-0 w-full h-full bg-[linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 82.19%)] z-[40]"></div>

      {/* image */}
      <div className="z-20 absolute top-0 left-0 w-full h-[160px]">
        <img
          src={info.picture}
          className="w-full h-full object-cover"
          alt=""
        />
      </div>

      {/* button actions */}
      <div className="flex absolute top-[10px] right-[10px] z-50 gap-2">
        <button
          className="p-[10px] rounded-sm h-[40px] w-[40px] text-xl text-darkBlue bg-white"
          onClick={() => navigate(edit, { state: { info } })}
        >
          <FiEdit />
        </button>
        <button
          className="p-[10px] rounded-sm h-[40px] w-[40px] text-lg text-darkBlue bg-white"
          onClick={onOpen}
        >
          <FaTrash />
        </button>
      </div>

      {/* info boat */}
      <div className="absolute bottom-[10px] left-[10px] w-[calc(100%_-_10px)] z-50">
        <p className="text-white font-bold text-lg">{info.name}</p>
        {info.code && (
          <p className="text-white font-bold uppercase text-xs">
            {info.code}
          </p>
        )}
      </div>
    </div>
  );
}
