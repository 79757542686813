import { useRef, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Virtual, Navigation } from "swiper";
import dayjs from "dayjs";

import CardDay from "../../components/CardDay";
import { CurrentDateContext } from "../../context/CurrentDateContext";

import "dayjs/plugin/utc";

SwiperCore.use([Virtual, Navigation, Pagination]);

export default function Days(props: any) {
  const { onChangeDate, reservas, isDriver } = props;
  const { currentDate, setCurrentDate, position, arraysDayComplete } =
    useContext(CurrentDateContext);

  const currentFixedDate = dayjs().format("YYYY-MM-DD");
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    if (swiperRef.current) {
      const currentDayIndex = arraysDayComplete.findIndex(
        (day: any) => {
          return day.fecha === currentDate;
        }
      );
    }
  }, [currentDate]);

  useEffect(() => {
    onChangeDate(currentDate);
  }, []);

  useEffect(() => {
    const slideTo = (index: number) => {
      swiperRef.current.slideTo(index - 2 ?? 0);
    };
    slideTo(position);
  }, [position]);

  return (
    <div className="flex justify-center ">
      <Swiper
        slidesPerView={6  }
        centeredSlidesBounds={true}
        centeredSlides={false}
        spaceBetween={10}
        className="mySwiper "
        initialSlide={arraysDayComplete.findIndex(
          (day: any) =>
            dayjs(day.fecha).format("YYYY-MM-DD") === currentDate
        )}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        virtual
      >
        {arraysDayComplete.map((date: any) => {
          const isActive = currentDate === date.fecha ? true : false;
          const isToday =
            currentFixedDate ===
            dayjs(date.fecha).format("YYYY-MM-DD");

          return (
            <SwiperSlide
              key={date.fecha}
              onClick={() => setCurrentDate(date.fecha)}
              virtualIndex={position}
            >
              <CardDay
                isDriver={isDriver}
                reservas={reservas}
                isToday={isToday}
                date={date}
                isActive={isActive}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
