export function tConvert(time: any) {
  // Check correct time format and split into components
  time = time
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "am" : "pm"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export function generateTimeSlots(
  startTime: string,
  endTime: string
) {
  const timeSlots = [];
  const startParts = startTime.split(":");
  const endParts = endTime.split(":");
  const startHour = parseInt(startParts[0], 10);
  const endHour = parseInt(endParts[0], 10);

  for (let hour = startHour; hour < endHour; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
      const formattedMinute = minute === 0 ? "00" : `${minute}`;
      const amPm = hour < 12 ? "am" : "pm";
      const timeSlot = {
        id: `${formattedHour}:${formattedMinute} ${amPm}`,
        start: `${formattedHour}:${formattedMinute} ${amPm}`,
      };
      timeSlots.push(timeSlot);
    }
  }

  return timeSlots;
}
