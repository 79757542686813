import React, { createContext, useState } from "react";

export const InfoDndContext = createContext<any>(null);

export default function InfoDndProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [infoDnd, setInfoDnd] = useState<any>({
    was_moved: false,
  });

  return (
    <InfoDndContext.Provider value={{ infoDnd, setInfoDnd }}>
      {children}
    </InfoDndContext.Provider>
  );
}
