import { useNavigate } from "react-router-dom";

export default function Auth() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="h-[70vh] relative">
        <div className="bg-neutral-950 opacity-50 absolute top-0 left-0 w-full h-full" />
        <img
          src="/images/bg-auth.png"
          alt=""
          className="w-full h-full object-cover"
        />
      </div>

      <div className="px-5 bg-white absolute bottom-0 left-0 -mt-9 w-full rounded-tl-2xl py-5 rounded-tr-2xl z-50">
        <h3 className="text-3xl font-bold text-darkBlue text-center">
          Welcome to Vvas
        </h3>
        <p className="mb-5 text-center text-darkBlue mt-1">
          Select your account type
        </p>

        <button
          className="mb-4 text-lg h-[54px] text-darkBlue bg-white border-darkBlue border w-full rounded-md"
          onClick={() => navigate("/login-admin")}
        >
          Admin
        </button>
        <button
          className="text-lg h-[54px] text-white bg-darkBlue border-darkBlue border w-full rounded-md"
          onClick={() => navigate("/login-driver")}
        >
          Driver
        </button>

        <p className="mt-5 text-neutral-400 text-center">
          &copy; 2023 VVAS
        </p>
      </div>
    </div>
  );
}
