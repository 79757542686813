import { useState } from "react";
import toast from "react-hot-toast";
import { Input, FormControl } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import BackButton from "../../../components/BackButton";
import Spinner from "../../../components/Spinner";
import { UPDATE_LOCATION } from "../../../graphql/mutations";

export default function EditLocation() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [updateResource, { loading }] = useMutation(UPDATE_LOCATION);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      id: location.state.info.id,
      name: location.state.info.name,
    },
  });

  console.log(errors);

  function onSubmit(values: { name: string }) {
    setIsCreating(true);
    updateResource({
      variables: {
        data: {
          ...values,
        },
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data?.updateLocation.status === 200) {
          const notify = () =>
            toast.success("Location updated successfully");
          notify();
          return navigate(-1);
        }

        const notify = () =>
          toast("A location with that name already exists", {
            icon: "⚠️",
          });
        notify();
      })
      .catch((error) => {
        console.log(error);
      });
    setIsCreating(false);
  }

  return (
    <div>
      <BackButton
        goBackRoute="/list-locations"
        title="EDIT LOCATION"
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between px-5 h-[calc(100vh_-_88px_-_32px)]">
          <FormControl isInvalid={!!errors.name}>
            <Input
              type="text"
              className="form-control-input"
              border="1px solid red"
              placeholder="Name of location"
              {...register("name", {
                required: "This is required",
              })}
            />
            <p className="text-danger text-sm mt-2">
              {/* @ts-ignore */}
              {errors.name && errors.name.message}
            </p>
          </FormControl>
          <button
            className="h-[54px] bg-darkBlue w-full text-white font-medium text-lg rounded-md"
            type="submit"
            disabled={!getValues().name}
          >
            {!!isSubmitting || !!loading ? (
              <Spinner />
            ) : isCreating ? (
              "Saving.."
            ) : (
              "Save changes"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
