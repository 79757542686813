export default function Button({ children, ...rest }: any) {
  return (
    <button
      className="bg-darkBlue h-14 rounded-md text-white text-center font-medium w-full text-xl"
      {...rest}
    >
      {children}
    </button>
  );
}
