import { useEffect, useState } from "react";
import { FaHome, FaPlus, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import SettingIcon from "../assets/icons/setting-icon.svg";
import SpongeIcon from "../assets/icons/sponge-icon.svg";
import BoatIcon from "../assets/icons/boat-icon.svg";
import PowerIcon from "../assets/icons/power-icon-home.svg";

export default function ButtonAdd({ pageIntern, route }: any) {
  const [showMenuAdd, setShowMenuAdd] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleTouchStart = () => {
    const pressTimer = setTimeout(() => {
      navigate("/macro-view");
    }, 700);

    const handleTouchEnd = () => {
      clearTimeout(pressTimer);
    };

    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("touchend", handleTouchEnd);
    };
  };

  useEffect(() => {
    const button: any = document.getElementById("myButton"); // Reemplaza 'myButton' con el ID de tu botón
    button.addEventListener("touchstart", handleTouchStart);

    return () => {
      button.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <>
      {showMenuAdd && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          exit={{ opacity: 0 }}
          onClick={() => setShowMenuAdd(false)}
          className={`block z-[200] fixed w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 left-0`}
        />
      )}

      {showMenuAdd ? (
        <div
          className={`fixed z-[250] ${
            pageIntern ? "bottom-[100px]" : "bottom-[96px]"
          } flex right-[15px] gap-y-3 flex-col bg-transparent items-end`}
        >
          {pathname === "/" ? (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.35 }}
              exit={{ opacity: 0 }}
              className="text-darkBlue font-bold w-max text-xl px-5 h-[56px] rounded-[8px] bg-lightPrimary flex items-center gap-3"
              onClick={() => navigate("/settings")}
            >
              <div>
                <img src={SettingIcon} alt="" className="w-6" />
              </div>
              <span className="block">Settings</span>
            </motion.button>
          ) : (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.35 }}
              exit={{ opacity: 0 }}
              className="text-darkBlue font-bold w-max text-xl px-5 h-[56px] rounded-[8px] bg-lightPrimary flex items-center gap-3"
              onClick={() => navigate("/")}
            >
              <div>
                <FaHome />
              </div>
              <span className="block">Home</span>
            </motion.button>
          )}
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.25 }}
            exit={{ opacity: 0 }}
            className="text-darkBlue font-bold w-max text-xl px-5 h-[56px] rounded-[8px] bg-lightPrimary flex items-center gap-3"
            onClick={() => navigate("/create-cleaning/cleaning")}
          >
            <div>
              <img src={SpongeIcon} alt="" />
            </div>
            <span className="block">Boat Cleaning</span>
          </motion.button>
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.15 }}
            exit={{ opacity: 0 }}
            className="text-darkBlue font-bold w-max text-xl px-5 h-[56px] rounded-[8px] bg-lightBlue flex items-center gap-3"
            onClick={() => navigate("/create-booking")}
          >
            <div>
              <img src={BoatIcon} alt="" />
            </div>
            <span className="block">Regular Reservation</span>
          </motion.button>
        </div>
      ) : null}

      {pathname === "/settings" && (
        <button
          className={`w-[55px] h-[55px] p-4 rounded-lg text-white bg-darkYellow z-50 ${
            pageIntern ? "bottom-[20px]" : "bottom-[20px]"
          } right-[82px] z-[250] text-[22px] shadow-[0px 4px 4px rgba(0, 0, 0, 0.25)] fixed`}
          onClick={() => navigate("/")}
        >
          <FaHome />
        </button>
      )}

      <button
        className={`select-none w-[55px] h-[55px] p-4 rounded-lg text-white ${
          showMenuAdd ? "bg-danger" : "bg-darkBlue"
        } ${
          pageIntern ? "bottom-[30px]" : "bottom-[20px]"
        } right-[15px] z-[250] text-[22px] shadow-[0px 4px 4px rgba(0, 0, 0, 0.25)] fixed shadow-2xl`}
        onClick={() => {
          if (pageIntern) return navigate(route!);
          setShowMenuAdd(!showMenuAdd);
        }}
      >
        <div
          id="myButton"
          className="hover:ring-2 hover:ring-offset-2 hover:ring-darkBlue absolute z-50 top-0 left-0 w-full h-full bg-transparent rounded-md"
        />

        {showMenuAdd ? (
          <FaTimes />
        ) : pageIntern ? (
          <FaPlus />
        ) : (
          <img src={PowerIcon} alt="" />
        )}
      </button>
    </>
  );
}
