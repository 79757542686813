import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { Button } from "../../ui";

interface IModalLocation {
  isOpen: boolean;
  onClose: () => void;
  onChangeLocation: (location: any) => void;
  data: any;
}

export default function ModalLocation({
  isOpen,
  onClose,
  data,
  onChangeLocation,
}: IModalLocation) {
  const locationClickHandler = (location: any) => {
    console.log("location item", location);
    onChangeLocation(location);
    onClose();
  };

  return (
    <AlertDialog.Root open={isOpen}>
      <AlertDialog.Portal className="z-[500]">
        <AlertDialog.Overlay
          onClick={() => {
            console.log("hello");
            onClose();
          }}
          className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
        />
        <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <div className="mb-3">
            <h3 className="text-darkBlue text-2xl font-bold">
              Select location
            </h3>
          </div>

          {data?.map((item: any) => (
            <button
              className="block mb-3 text-xl text-darkBlue"
              key={item.id}
              onClick={() => locationClickHandler(item)}
            >
              {item.name}
            </button>
          ))}

          <div className="flex justify-end">
            <button
              className="font-bold text-darkBlue uppercase bg-lightBlue p-[14px_44px] rounded-md mt-1"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
