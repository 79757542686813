import { FaTimes } from "react-icons/fa";

export default function Modal({
  show,
  title,
  onClose,
  children,
  height,
}: any) {
  const calcHeight =
    +height! + 32 + 10 + 40 + 5 + (+height! / 165 - 1) * 15;

  return (
    <div
      className={`${
        show ? "block" : "hidden"
      } fixed bottom-[30px] right-0 left-0 mx-auto z-[10000] w-[90%] max-w-[480px] overflow-hidden shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)]`}
      style={{ height: calcHeight }}
    >
      <div
        className="w-full h-full fixed top-0 left-0 z-[120] bg-[rgba(0,0,0,0.6)]"
        onClick={() => onClose(false)}
      />

      <div className="p-4 absolute left-0 bottom-0 bg-white w-full h-full z-[130] overflow-y-auto rounded-md">
        <div className="h-[40px] items-center flex justify-between">
          <p className="text-2xl font-bold text-darkBlue">
            {title || "Modal header"}
          </p>
          <button
            className="bg-transparent text-xl text-gray-500"
            onClick={() => onClose(false)}
          >
            <FaTimes />
          </button>
        </div>
        <div className="mt-[15px]">{children}</div>
      </div>
    </div>
  );
}
