import { MdOutlineFilterAlt } from "react-icons/md";
import { RiSteering2Line } from "react-icons/ri";
import {
  IoCalendarOutline,
  IoStatsChartOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import * as AlertDialog from "@radix-ui/react-alert-dialog";

import { Button } from "../../ui";

interface IModalOptions {
  isOpen: boolean;
  onClose: () => void;
  onOpenBoatFilterModal: () => void;
  onOpenModalCalendar: () => void;
}

export default function ModalOptions({
  isOpen,
  onClose,
  onOpenBoatFilterModal,
  onOpenModalCalendar,
}: IModalOptions) {
  const navigate = useNavigate();

  return (
    <AlertDialog.Root open={isOpen}>
      <AlertDialog.Portal className="z-[500]">
        <AlertDialog.Overlay
          onClick={() => {
            console.log("hello");
            onClose();
          }}
          className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
        />
        <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <div className="mb-3">
            <h3 className="font-bold text-2xl text-darkBlue">
              Options
            </h3>
          </div>

          <Button
            className="h-auto w-full text-left flex justifystart py-1 bg-transparent mb-[10px]"
            onClick={() => {
              onClose();
              onOpenBoatFilterModal();
            }}
          >
            <span className="text-lg text-darkBlue w-8 h-8 flex border border-lightBlue justify-center items-center rounded-md">
              <MdOutlineFilterAlt />
            </span>

            <span className="block text-xl ml-[10px] text-darkBlue">
              Filter
            </span>
          </Button>
          <Button
            className="h-auto w-full text-left flex justifystart py-1 bg-transparent mb-[10px]"
            onClick={() => {
              onClose();
              onOpenModalCalendar();
            }}
          >
            <span className="text-lg text-darkBlue w-8 h-8 flex border border-lightBlue justify-center items-center rounded-md">
              <IoCalendarOutline />
            </span>

            <span className="block text-xl ml-[10px] text-darkBlue">
              Calendar
            </span>
          </Button>

          <Button
            className="h-auto w-full text-left flex justifystart py-1 bg-transparent mb-[10px]"
            onClick={() => navigate("/driver-confirm")}
          >
            <span className="text-lg text-darkBlue w-8 h-8 flex border border-lightBlue justify-center items-center rounded-md">
              <RiSteering2Line />
            </span>

            <span className="block text-xl ml-[10px] text-darkBlue">
              Driver Confirmation
            </span>
          </Button>

          <Button
            className="h-auto w-full text-left flex justifystart py-1 bg-transparent mb-[10px]"
            onClick={() => navigate("/macro-view")}
          >
            <span className="text-lg text-darkBlue w-8 h-8 flex border border-lightBlue justify-center items-center rounded-md">
              <IoStatsChartOutline />
            </span>

            <span className="block text-xl ml-[10px] text-darkBlue">
              Macro View
            </span>
          </Button>

          <div className="flex justify-end">
            <button
              className="font-bold text-darkBlue uppercase bg-lightBlue p-[14px_44px] rounded-md mt-1"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
