import * as yup from "yup";
import { FormControl } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";
import { toast } from 'react-hot-toast';

import BackButton from "../components/BackButton";
import Spinner from "../components/Spinner";
import { LOGIN_EMPLOYEE } from "../graphql/mutations";
import { useAuthContext } from "../providers/AuthProvider";
import { Button } from "../ui";

const schema = yup.object().shape({
  code: yup.string().required(),
});

export default function LoginDriver() {
  const { login, refetchGlobalData, loadingGlobalData } =
    useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
    },
    resolver: yupResolver(schema),
  });

  const [loginMutation, { loading: loginLoading }] =
    useMutation(LOGIN_EMPLOYEE);

  const onSubmit = (data: any) => {
    
    loginMutation({
      variables: {
        accessCode: data.code,
      },
    })
      .then((res) => {
        if(res?.data.signInEmployee.message === "employee not found") {
          toast.error("Incorrect Credentials");
          return;
        }
        
        login(res?.data.signInEmployee.token);
        refetchGlobalData();
        toast.success("You are logged in");
      })
      .catch((error) => {
        toast.error('Error')
      });;
  };

  return (
    <>
      <BackButton goBackRoute="/auth" title="" />

      <div className="px-5">
        <div className="mb-5">
          <h3 className="text-3xl text-darkBlue text-center font-black">
            ACCESS YOUR ACCOUNT
          </h3>

          <p className="mt-2 text-center text-darkBlue">
            Enter your information below and access your VVAS account
            to manage your schedule.
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl mb="10px">
            <label
              htmlFor="email"
              className="text-sm font-semibold block mb-1 text-darkBlue"
            >
              Code
            </label>

            <input
              className="form-input"
              id="code"
              type="text"
              placeholder="Code"
              {...register("code")}
            />
            {errors.code && (
              <p className="text-red-500 text-sm mt-1">
                {errors.code.message}
              </p>
            )}
          </FormControl>

          <div className="mt-4">
            <Button>
              {loadingGlobalData || loginLoading ? (
                <Spinner />
              ) : (
                "Sign In"
              )}
            </Button>
          </div>

          <p className="mt-4 text-center text-gray-500 text-xs">
            &copy;2023 VVAS
          </p>

        </form>
      </div>
    </>
  );
}
