import Router from "./routes/router";
import { Outlet } from 'react-router-dom'
import { useEffect } from "react";
import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: "https://206ed03a0b03ee1698d0365afd96511b@o1340801.ingest.sentry.io/4506072860131328",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api\.vvassoftware\.com\/graphql/, /^https:\/\/vvas-api\.dev\.mostaza\.org\/graphql/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App() {
  //  const [permission, setPermission] = useState(Notification.permission);

  // const requestPermission = async () => {
  //   const result = await Notification.requestPermission();
  //   setPermission(result);
  // };

  // try{
  //   alert(`hola ${Notification.permission}`)
  // }catch(er){
  //   alert(`error ${er}`)
  // }

  // if (window.safari !== undefined) {
  //   const permissionData = window.safari.pushNotification.permission('your-website-push-id');
  //   if (permissionData.permission === 'default') {
  //     window.safari.pushNotification.requestPermission(
  //       'https://your-website.com', // your website URL
  //       'your-website-push-id', // your website push ID
  //       {}, // push package data
  //       (permissionData: any) => {
  //         console.log(permissionData);
  //       }
  //     );
  //   }
  // }
  // };

  // const [playerId, setPlayerId] = useState(null);

  // useEffect(() => {
  //   if (window.OneSignal) {
  //     window.OneSignal.push(async function() {
  //       // Si el usuario ya está suscrito, esto devolverá el playerId
  //       const userId = await window.OneSignal.getUserId();
  //       setPlayerId(userId);

  //       // Si aún no está suscrito, puede escuchar el evento 'subscriptionChange'
  //       window.OneSignal.on('subscriptionChange', async function(isSubscribed) {
  //         if (isSubscribed) {
  //           // Cuando el usuario se suscribe, obtén el nuevo playerId
  //           const newUserId = await window.OneSignal.getUserId();
  //           setPlayerId(newUserId);
  //         }
  //       });
  //     });
  //   }
  // }, []);


  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Puedes agregar un mensaje personalizado aquí
  //     event.returnValue = '¿Seguro que deseas abandonar esta página?';
  //   };

  //   // Agregar el evento beforeunload
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     // Asegúrate de eliminar el evento cuando el componente se desmonte
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
  

  

  return (
    <>
      <div className="flex-col items-center justify-center flex h-screen landscape-alert">
        <h3 className="text-3xl mb-[10px] font-bold">Oops!</h3>
        <p>The app is not available on landscape mode yet.</p>
      </div>
      <div className="main-body   h-screen" >
        {/* <p>
{playerId ? <p>Player ID: {playerId}</p> : <p>Not subscribed</p>}
</p> */}
        {/* <div>
              {permission === 'granted' ? (
                <p>Permission granted!</p>
              ) : (
                <button onClick={requestPermission}>Request permission</button>
              )}
        </div> */}

        <Router />
      </div>
    </>
  );
}
