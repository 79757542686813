import { useEffect } from "react";

import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { RESOURCES_QUERY } from "../../../graphql/querys";

import BackButton from "../../../components/BackButton";

import CardEdit from "../../../components/Settings/CardEdit";
import { BoatListLoader } from "../../../components/Loader";
import ButtonAdd from "../../../components/ButtonAdd";

export default function ListBoats() {
  const location = useLocation();
  const { data, loading, error, refetch } = useQuery(RESOURCES_QUERY);

  useEffect(() => {
    refetch();
  }, [location]);

  const onDelete = () => {
    refetch();
  };

  if (loading) return <BoatListLoader />;
  if (error) return <p>Error...</p>;

  return (
    <div>
      <BackButton goBackRoute="/settings" title="boats" />

      <div className="grid grid-cols-2 px-5 gap-5">
        {data.resourcesByBusiness.map((boat: any) => (
          <CardEdit
            key={boat.id}
            type="boat"
            edit={`/edit-boat/${boat.id}`}
            route="/create-boat"
            refetch={refetch}
            onDelete={onDelete}
            pageIntern
            info={{
              id: boat.id,
              color: "#00000",
              name: boat.name,
              picture: boat.picture,
              description: boat.description,
            }}
          />
        ))}
      </div>

      <ButtonAdd pageIntern route="/create-boat" bottom="30px" />
    </div>
  );
}
