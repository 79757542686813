interface IModalTime {
  title?: string;
  show: boolean;
  onClose: (value: boolean) => void;
  children: React.ReactNode;
  height: any;
}

export default function ModalConfirm({
  show,
  title,
  onClose,
  children,
  height,
}: IModalTime) {
  return (
    <div
      className={`right-0 left-0 mx-auto max-w-[480px] w-[90%] z-[100] ${
        show ? "block" : "hidden"
      } shadow-[0px_0px_15px_0px_rgba(0,0,0,0.1)] fixed bottom-[30px] overflow-hidden`}
      style={{
        height:
          typeof height === "string"
            ? "230px"
            : +height! + 32 + 10 + 40 + 5 + (+height! / 165 - 1) * 15,
      }}
    >
      <div
        className="w-full h-full fixed top-0 left-0 z-[120] bg-[rgba(0,0,0,0.6)]"
        onClick={() => onClose(false)}
      />

      <div
        className="z-[130] rounded-md p-4 absolute left-0 bottom-0 bg-white w-full h-full"
        style={{
          overflowY: typeof height === "string" ? "hidden" : "auto",
        }}
      >
        <div className="h-[40px] flex items-center justify-between">
          <p className="text-2xl font-bold text-darkBlue">
            {title || "Modal header"}
          </p>
        </div>
        <div className="mt-[15px]">{children}</div>
      </div>
    </div>
  );
}
