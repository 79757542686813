import { createContext, useState } from "react";

export const ClashAltBookingContext = createContext<any>(null);

export default function ClashAltBookingProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [bookingClash, setBookingClash] = useState<string[]>([]);

  return (
    <ClashAltBookingContext.Provider
      value={{ bookingClash, setBookingClash }}
    >
      {children}
    </ClashAltBookingContext.Provider>
  );
}
