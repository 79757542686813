import { RiArrowDownSLine } from "react-icons/ri";

export default function ButtonSelect({
  children,
  isValue,
  isEmpty,
  ...rest
}: any) {
  return (
    // @ts-ignore
    <button
      type="button"
      className={`w-full h-[46px] border ${
        isEmpty ? "border-red-600" : "border-lightBlue"
      } text-lightBlue rounded-md px-4 bg-white text-left block relative text-sm`}
      {...rest}
    >
      {children}

      {!isValue && (
        <div className="absolute top-1/2 right-2 -translate-y-1/2">
          <RiArrowDownSLine />
        </div>
      )}
    </button>
  );
}
