export default function Input({ isEmpty, ...rest }: any) {
  return (
    // @ts-ignore
    <input
      className={`h-[46px] w-full rounded-md border ${
        isEmpty ? "border-red-500" : "border-lightPrimary"
      } px-3`}
      {...rest}
    />
  );
}
