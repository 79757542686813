import { useMutation } from "@apollo/client";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { toast } from "react-hot-toast";

import dayjs from "dayjs";
import {
  REJECT_BOOKINGS_SCHEDULE,
  CONFIRM_BOOKINGS_SCHEDULE,
} from "../../graphql/mutations";
import { useContext } from "react";
import { CurrentDateContext } from "../../context/CurrentDateContext";
import Spinner from "../Spinner";

enum STATUS_CONFIRM_ENUM {
  REJECTED = "REJECTED",
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
}

interface IModalFeedbackConfirm {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  statusConfirm: STATUS_CONFIRM_ENUM;
  bookingIds: string[];
  setIsUpdated: (value: boolean) => void;
  refetchBookingsByEmployee: () => void;
}

export default function ModalFeedbackConfirm({
  isOpen,
  onClose,
  statusConfirm,
  setIsUpdated,
  refetchBookingsByEmployee,
}: IModalFeedbackConfirm) {
  const { currentDate } = useContext(CurrentDateContext);
  const [
    confirmBookingsSchedule,
    { loading: loadingConfirmBookings },
  ] = useMutation(CONFIRM_BOOKINGS_SCHEDULE);

  const [rejectBookingsSchedule, { loading: loadingRejectBookings }] =
    useMutation(REJECT_BOOKINGS_SCHEDULE);

  const handleConfirmBookings = () => {    
    confirmBookingsSchedule({
      variables: {
        date: dayjs(currentDate).startOf("day").format(),
      },
    })
      .then((r) => {
        refetchBookingsByEmployee();
        setIsUpdated(true);
        console.log(r);
        toast.success("Schedule Accepted!");
        onClose(false);
        setIsUpdated(false);
      })
      .catch((e) => {
        console.log("error", e);
        toast.error("Error!");
      });
  };

  const handleRejectBookings = () => {
    rejectBookingsSchedule({
      variables: {
        date: dayjs(currentDate).startOf("day").format(),
      },
    })
      .then((r) => {
        refetchBookingsByEmployee();
        setIsUpdated(true);
        console.log(r);
        toast.error("Schedule Rejected!");
        onClose(false);
        setIsUpdated(false);
      })
      .catch((e) => {
        console.log("error", e);
        toast.error("Error!");
      });
  };

  return (
    <AlertDialog.Root open={isOpen}>
      <AlertDialog.Portal className="z-[500]">
        <AlertDialog.Overlay
          onClick={() => {
            console.log("hello");
            onClose(false);
          }}
          className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
        />
        <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-5 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <div className="grid grid-cols-[32px_1fr] gap-3 items-center mb-[10px]">
            <div className="mr-[8px] w-8">
              <img
                src={
                  statusConfirm === STATUS_CONFIRM_ENUM.PENDING
                    ? "/icons/driver-pending-icon.svg"
                    : statusConfirm === STATUS_CONFIRM_ENUM.REJECTED
                    ? "/icons/driver-rejected-icon.svg"
                    : statusConfirm === undefined
                    ? "/icons/driver-normal-icon.svg"
                    : "/icons/driver-accepted-icon.svg"
                }
                alt=""
              />
            </div>

            <h3 className="font-bold text-xl text-darkBlue">
              Schedule{" "}
              {statusConfirm === STATUS_CONFIRM_ENUM.PENDING
                ? "Confirmation"
                : statusConfirm === STATUS_CONFIRM_ENUM.REJECTED
                ? "Rejected"
                : statusConfirm === undefined
                ? "without reservations to accept"
                : "Accepted"}
            </h3>
          </div>

          <p className="text-darkBlue mb-4">
            {statusConfirm === STATUS_CONFIRM_ENUM.PENDING
              ? "Please accept your schedule for the day."
              : statusConfirm === STATUS_CONFIRM_ENUM.CONFIRMED
              ? "Thanks! You have confirmed you will attend your assigned schedule."
              : statusConfirm === undefined
              ? "At the moment there are no reservations to confirm"
              : "You have rejected your schedule. We will notify the administrator to rearrange it."}
          </p>

          {statusConfirm === STATUS_CONFIRM_ENUM.PENDING && (
            <div className="grid mt-4 grid-cols-2 gap-5">
              <div />
              <button
                className="h-[50px] rounded-md bg-darkBlue text-white"
                onClick={handleConfirmBookings}
              >
                {loadingConfirmBookings ? <Spinner /> : "Accept"}
              </button>
            </div>
          )}

          {statusConfirm !== STATUS_CONFIRM_ENUM.PENDING && (
            <button
              className="text-darkBlue uppercase bg-lightBlue h-[54px] p-[0px_44px] rounded-md"
              onClick={() => onClose(false)}
            >
              Close
            </button>
          )}
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
