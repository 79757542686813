import { FaAngleDown } from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

interface IHeader {
  onOpenLocationModal: () => void;
  onOpenOptionsModal: () => void;
  currentLocation: any;
}

export default function Header({
  onOpenLocationModal,
  onOpenOptionsModal,
  currentLocation,
}: IHeader) {
  return (
    <div className="flex px-5 py-4 justify-between items-center">
      <div>
        <button
          className="flex items-center gap-1 h-auto text-darkBlue p-0 bg-transparent"
          onClick={onOpenLocationModal}
        >
          <span className="block text-2xl font-bold">
            {currentLocation?.name}
          </span>
          <span className="block ml-[3px] text-xl">
            <FaAngleDown />
          </span>
        </button>
      </div>

      <div>
        <button
          className="text-[28px] h-auto bg-transparent text-darkBlue border border-darkBlue rounded-sm p-[6px]"
          onClick={onOpenOptionsModal}
        >
          <MdOutlineKeyboardArrowDown />
        </button>
      </div>
    </div>
  );
}
