import { createContext, useState } from "react";

export const ResourceFilterContext = createContext<any>(null);

export default function ResourceFilterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [resourceSelect, setResourceSelect] = useState<string | null>(
    null
  );

  return (
    <ResourceFilterContext.Provider
      value={{
        resourceSelect,
        setResourceSelect,
      }}
    >
      {children}
    </ResourceFilterContext.Provider>
  );
}
