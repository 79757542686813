import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import * as AlertDialog from "@radix-ui/react-alert-dialog";

import ButtonAdd from "../ButtonAdd";
import { DELETE_LOCATION_MUTATION } from "../../graphql/mutations";
import Spinner from "../Spinner";
import { toast } from "react-hot-toast";

interface ICardLocation {
  route: string;
  pageIntern: boolean;
  edit: string;
  onDelete: (id: any) => void;
  info: {
    id: string;
    name: string;
  };
}

export default function CardLocation({
  onDelete,
  info,
  route,
  pageIntern,
  edit,
}: ICardLocation) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const navigate = useNavigate();
  const [deleteLocation, { loading }] = useMutation(
    DELETE_LOCATION_MUTATION
  );

  const handleDelete = () => {
    deleteLocation({
      variables: {
        id: info.id,
      },
    })
      .then((r) => {
        toast.success(`${info.name} was deleted`);
        onDelete(info.id);
        onClose();
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  return (
    <div className="flex p-[10px_16px] border border-lightBlue rounded-md items-center justify-between mb-[15px]">
      {/* Modal */}
      <AlertDialog.Root open={isOpen}>
        <AlertDialog.Portal className="z-[500]">
          <AlertDialog.Overlay
            onClick={() => {
              console.log("hello");
              onClose();
            }}
            className="bg-black opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-[2500]"
          />
          <AlertDialog.Content className="z-[2500] data-[state=open]:animate-contentShow fixed top-1/2 left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] -translate-y-1/2 rounded-[6px] bg-white p-[20px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <header className="mb-3">
              <h3 className="font-bold text-darkBlue text-2xl leading-[1.1]">
                Do you want to delete {info.name}?
              </h3>
            </header>

            <main className="my-5">
              <p>This will delete this location permanently</p>
            </main>

            <footer className="flex items-center gap-3">
              <button
                className="w-full text-white uppercase bg-darkBlue p-[14px_0] h-auto rounded-md mr-[10px]"
                onClick={handleDelete}
              >
                {loading ? <Spinner /> : "Delete"}
              </button>
              <button
                className="w-full text-darkBlue uppercase bg-lightBlue p-[14px_0] h-auto rounded-md"
                onClick={onClose}
              >
                Close
              </button>
            </footer>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>

      <div>
        <p className="text-xl text-darkBlue font-semibold">
          {info.name}
        </p>
      </div>
      <div className="flex">
        <button
          className="w-[40px] grid place-items-center h-[40px] rounded-md bg-lightPrimary text-darkBlue text-lg p-0"
          onClick={() => navigate(edit, { state: { info } })}
        >
          <FiEdit />
        </button>
        <button
          className="w-[40px] h-[40px] grid place-items-center rounded-md bg-darkBlue text-white p-0 ml-[10px]"
          onClick={onOpen}
        >
          <FaTrash />
        </button>
      </div>

      <ButtonAdd
        route={route}
        pageIntern={pageIntern}
        bottom="30px"
      />
    </div>
  );
}
