import dayjs from "dayjs";
import { TbSteeringWheel } from "react-icons/tb";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import BackButton from "../../components/BackButton";
import { BOOKING_QUERY } from "../../graphql/querys";
import { GET_EMPLOYEE } from "../../graphql/querys";
import SpinnerCircle from "../../components/Loader/Spinner";

function ItemDriver({ driver }: any) {
  const {
    data: employeeData,
    loading: loadingEmployee,
    error: errorEmployee,
    refetch: refetchEmployee,
  } = useQuery(GET_EMPLOYEE, {
    variables: {
      id: driver?.employee.id,
    },
  });

  if (loadingEmployee) return null;

  return (
    <div className="rounded-md overflow-hidden relative w-full h-[150px]">
      <div className="absolute z-[70] w-full h-full bg-[rgba(0,0,0,0.3)]" />

      <div className="absolute text-[#333] text-2xl rounded-md p-[3px] top-[7px] right-[7px] bg-white z-50">
        <TbSteeringWheel />
      </div>

      {employeeData?.employee && (
        <img
          className="absolute top-0 left-0 z-50 object-cover w-full h-full"
          src={employeeData?.employee.picture}
          alt=""
        />
      )}
      <p className="absolute bottom-[8px] left-[10px] font-semibold text-white uppercase z-[80]">
        {employeeData?.employee.name}
      </p>
    </div>
  );
}

export default function DriverBookingView() {
  const { id } = useParams();

  const { data, loading, error } = useQuery(BOOKING_QUERY, {
    variables: {
      id,
    },
  });

  const booking = data?.booking;

  if (loading)
    return (
      <div className="grid place-items-center h-screen">
        <SpinnerCircle />
      </div>
    );

  if (error) return <p>Error...</p>;

  return (
    <div className="">
      <div className="relative z-20">
        <BackButton
          goBackRoute="/driver-home"
          title="Reservation details"
          // @ts-ignore
          color={!booking?.resource ? "#1B4965" : "white"}
        />
      </div>

      {booking?.resource ? (
        <div className="-z-[1] -mt-[88px] bg-cover bg-center h-[390px] w-full max-w-[480px] mx-auto relative">
          <div className="absolute top-0 left-0 w-full h-full z-[100]">
            <img
              src={booking.resource.picture}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex z-[140] absolute bottom-16 right-[20px] flex-col items-end">
            <p className="text-[28px] text-white uppercase font-bold">
              {booking.resource.name}
            </p>
            <p className="uppercase text-white text-lg">
              {dayjs(booking.startDate).format("MMMM DD, YYYY")}
            </p>
            <p className="text-white font-extrabold text-xl uppercase">
              {dayjs(booking.startDate).format("hh:mm A")} to{" "}
              {dayjs(booking.endDate).format("hh:mm A")}
            </p>
          </div>
          <div className="w-full h-[390px] z-[130] absolute top-0 left-0 bg-[linear-gradient(0deg,_rgba(0,0,0,0.95)_10%,_rgba(255,255,255,0)_100%)]" />
        </div>
      ) : (
        <div className="grid place-items-center h-[390px] bg-lightPrimary relative p-5">
          {booking?.type === "CLEANING" ? (
            <p>
              This is a boat cleaning assignment. There is no boat
              selected.
            </p>
          ) : (
            <p>There is no boat selected yet.</p>
          )}

          <div className="flex z-[140] absolute bottom-14 right-5 flex-col items-end">
            <p className="uppercase text-darkBlue text-lg">
              {dayjs(booking?.startDate).format("MMMM DD, YYYY")}
            </p>
            <p className="text-xl uppercase text-darkBlue font-extrabold">
              {dayjs(booking?.startDate).format("hh:mm A")} to{" "}
              {dayjs(booking?.endDate).format("hh:mm A")}
            </p>
          </div>
        </div>
      )}

      <div className="-mt-12 bg-white rounded-tl-[30px] rounded-tr-[30px] p-5 relative z-20">
        <div className="bg-darkBlue p-[15px_20px] rounded-lg">
          <p className="text-[28px] text-white font-bold">
            {booking.clientName}
          </p>
          <p className="text-white text-sm">
            {booking.location.name}, {booking.pickup}
          </p>
        </div>
      </div>

      <div className="bg-white px-5">
        <p className="font-bold text-darkBlue uppercase text-lg mb-[10px]">
          Driver
        </p>

        <div className="grid grid-cols-2 gap-[15px]">
          {booking?.bookingEmployees.map((i: any) => (
            <ItemDriver driver={i} key={i.id} />
          ))}
        </div>
      </div>

      <div className={`p-5 ${booking.description.length > 0 ? "pb-7" : "pb-0"}`}>
        <p className="">{booking.description}</p>
      </div>
    </div>
  );
}
