export default function BoatListLoader() {
  return (
    <div>
      <div className="flex items-center p-5">
        <div className="mr-[15px] bg-gray-300 w-12 h-12 rounded-sm" />
        <div className="w-[150px] h-[15px] rounded-sm bg-gray-400" />
      </div>

      <div className="grid px-5 gap-5 grid-cols-2">
        <div className="w-full h-[160px] bg-gray-400 rounded-sm" />
        <div className="w-full h-[160px] bg-gray-400 rounded-sm" />
        <div className="w-full h-[160px] bg-gray-400 rounded-sm" />
        <div className="w-full h-[160px] bg-gray-400 rounded-sm" />
      </div>
    </div>
  );
}
