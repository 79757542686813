import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useApolloClient, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { FaCheck, FaTimes } from "react-icons/fa";
import {DateTime} from 'luxon'

import Spinner from "../components/Spinner";
import { useAuthContext } from "../providers/AuthProvider";
import { BOOKINGS_FILTERED_QUERY } from "../graphql/querys";
import { EDIT_SCHEDULE_MUTATION } from "../graphql/mutations";
import LayoutAdmin from "../components/Layout/Admin";
import Header from "../components/Home/Header";
import Days from "../components/Home/Days";
import ModalLocation from "../components/Modals/Location";
import ModalOptions from "../components/Modals/Options";
import ButtonAdd from "../components/ButtonAdd";
import ModalFilterBoats from "../components/Modals/FilterBoats";
import ModalCalendar from "../components/Modals/Calendar";
import Poc from "../components/Poc";

import { CurrentDateContext } from "../context/CurrentDateContext";
import { ResourceFilterContext } from "../context/ResourceFilterContext";
import SpinnerCircle from "../components/Loader/Spinner";
// import { InfoDndContext } from "../context/InfoDndContext";
import { generateTimeSlots } from "../utils/time";
import { getTimeWithPrefixFromOffset } from "../utils/fechas";
import { ClashAltBookingContext } from "../context/ClashAltBooking";
import * as Sentry from "@sentry/react";



function FullScreenSpinner() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-[9999]">
      <SpinnerCircle />
    </div>
  );
}


// TODO: fix typo later
// @ts-ignore
const calcSideGrid = (base, hours) => {
  let times = [];

//  const base = dayjs().hour(6).minute(0);
console.log("hours", hours)

  for (let i = 0; i < hours; i++) {
    times.push({
      id: i,
      start: base.add(60 * i, "minute").format("hh A"),
    });

    base.add(60, "minute");
  }
  return times;
};




export default function Home() {
  const [isOpenLocationModal, setIsOpenLocationModal] =
    useState<boolean>(false);

  const principalContentRef = useRef<HTMLDivElement>(null);
  const principalContentHeaderRef = useRef<HTMLDivElement>(null);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  const onOpenLocationModal = () => setIsOpenLocationModal(true);
  const onCloseLocationModal = () => setIsOpenLocationModal(false);

  const [isOpenOptionsModal, setIsOpenOptionsModal] =
    useState<boolean>(false);

    const [TIMES, setTIMES] = useState([])

  const onOpenOptionsModal = () => setIsOpenOptionsModal(true);
  const onCloseOptionsModal = () => setIsOpenOptionsModal(false);

  const [isOpenFilterBoatsModal, setIsOpenFilterBoatsModal] =
    useState<boolean>(false);

  const onOpenFilterBoatsModal = () =>
    setIsOpenFilterBoatsModal(true);
  const onCloseFilterBoatsModal = () =>
    setIsOpenFilterBoatsModal(false);

  const [isOpenModalCalendar, setIsOpenModalCalendar] =
    useState<boolean>(false);

  const onOpenModalCalendar = () => setIsOpenModalCalendar(true);
  const onCloseModalCalendar = () => setIsOpenModalCalendar(false);

  const navigate = useNavigate();
  const { globalData, loadingGlobalData } = useAuthContext();
  const [hasChangeBooking, setHasChangeBooking] =
    useState<boolean>(false);

  const {
    currentDate,
    setCurrentDate,
    locationItem,
    setLocationItem,
  } = useContext(CurrentDateContext);

  const [editScheduleMutation, { loading: loadingEditSchedule }] =
    useMutation(EDIT_SCHEDULE_MUTATION);

  const [reservasFiltered, setReservasFiltered] = useState([]);
  const { resourceSelect } = useContext(ResourceFilterContext);

  // const [locationItem, setLocationItem] = useState<any>(null);
  const [date, setDate] = useState(dayjs(currentDate).format());
  const [reservas, setReservas] = useState<any>([]);

  const [isDirty, setIsDirty] = useState(false);
  const [dirtyData, setDirtyData] = useState<any>(null);
  const [dirtyTemp, setDirtyTemp] = useState<any>(null);
  // const { infoDnd, setInfoDnd } = useContext(InfoDndContext);

  const [showingToast, setShowingToast] = useState<boolean>(false);

  const [loadingBooking, setLoadingBooking] =
    useState<boolean>(false);

  const [loaderDriverHoursPerDay, setLoaderDriverHoursPerDay] =
    useState<boolean>(false);

  const openSchool = globalData?.business.startTime;
  const endSchool = globalData?.business.endTime;

  const timeSlots = generateTimeSlots(openSchool, endSchool);

  const apolloClient = useApolloClient();

  const refreshBookings = async () => {
    setLoadingBooking(true);

    apolloClient
      .query({
        fetchPolicy: "network-only",
        query: BOOKINGS_FILTERED_QUERY,
        variables: {
          date: dayjs(currentDate).toDate(),
        },
      })
      .then((res: any) => {
        const { bookingsFiltered } = res.data;
        //   console.log("res bookings", bookingsFiltered);

        //              boatName:"RX900 GX", driver:"Morgan", from: 0, duration:1, type:"used", id:1105, cliente: "Sophia"

        let reservasPayload: any = [];

        bookingsFiltered.map((i: any) => {
          //            return { boatName:"RX900 GX", driver:"Morgan", from: 0, duration:1, type:"used", id:1105, cliente: "Sophia" }
          const startDate = dayjs(i.startDate);
          const endDate = dayjs(i.endDate);



//          const horaBaseLuxon = dayjs(  DateTime.fromFormat( `${startDate.format('YYYY-MM-DD')} ${globalData.business.startTime.substr(0, 5) }`, 'yyyy-MM-dd hh:mm', { zone: globalData.business.timeZone }).toUTC().toISO()  );
//          const horaBase = dayjs(startDate).hour(6).minute(0);
           const horaBase= dayjs(  DateTime.fromFormat( `${startDate.format('YYYY-MM-DD')} ${globalData.business.startTime.substr(0, 5) }`, 'yyyy-MM-dd hh:mm', { zone: globalData.business.timeZone }).toUTC().toISO()  );

          //  console.log("horabase horaBaseLuxon",   horaBaseLuxon )
          //  console.log("horabase", horaBase, globalData)


          const diffInMinutes = endDate.diff(startDate, "minute");

          i.bookingEmployees
            .map((e: any) => {
              // i.employee.name
              const pack = {
                id: i.id,
                date: i.date,
                color: e.employee.bgColor,
                boatName: i.resource?.name,
                locationId: i.location.id,
                pickup: i.pickup,
                driver: e.employee.name,
                bookingType: i.type,
                driverObj: e.employee,
                driversAdicionales: i.bookingEmployees
                  .filter((a: any) => a.employee.id !== e.employee.id)
                  .map((a: any) => a.employee),
                from: startDate.diff(horaBase, "minutes") / 30,
                duration: diffInMinutes / 30,
                type: "used",
                cliente: i.clientName,
              };

              // console.log("OFFSET EN MINUTOS ",
              // horaBase,
              // startDate,
              // startDate.diff(horaBase, "minutes")
              // )

              //    console.log("pack", i.bookingEmployees, pack);

              reservasPayload.push(pack);
            })
            .join(", ");
        });

        // bookingsFiltered.map((i: any) => {
        //   //            return { boatName:"RX900 GX", driver:"Morgan", from: 0, duration:1, type:"used", id:1105, cliente: "Sophia" }
        //   const startDate = dayjs(i.startDate);
        //   const endDate = dayjs(i.endDate);

        //   const diffInHours = endDate.diff(startDate, "hour");

        //   const driver = i.bookingEmployees
        //     .map((i: any) => i.employee.name)
        //     .join(", ");

        //   const pack = {
        //     id: i.id,
        //     color: i.bookingEmployees[0].employee.bgColor,
        //     boatName: i.resource.name,
        //     driver,
        //     from: startDate.hour() - 6,
        //     duration: diffInHours,
        //     type: "used",
        //     cliente: i.clientName,
        //   };

        //   console.log("pack", i.bookingEmployees, pack);

        //   return pack;
        // })

        console.log("reservasPayload", reservasPayload);

        setReservas(reservasPayload);

        // setReservas( [1,2] )
      })
      .catch((err: any) => {
        Sentry.captureException(err);
        console.log("err", err);
      })
      .finally(() => {
        setLoadingBooking(false);
      });
  };

  const [elementCoords, setElementCoords] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  //  console.log({ elementCoords });

  // useEffect(() => {
  //   const handleScrollRight = () => {
  //     if (principalContentRef.current) {
  //       principalContentRef.current.scrollBy({
  //         left: 80,
  //         behavior: "instant",
  //       });
  //     }
  //   };

  //   const handleScrollLeft = () => {
  //     if (principalContentRef.current) {
  //       principalContentRef.current.scrollBy({
  //         left: -80,
  //         behavior: "smooth",
  //       });
  //     }
  //   };

  //   const handleScrollDown = () => {
  //     if (principalContentRef.current) {
  //       principalContentRef.current.scrollBy({
  //         top: 80,
  //         behavior: "smooth",
  //       });
  //     }
  //   };

  //   const innerWidthScreen = window.innerWidth;
  //   const innerHeightScreen = window.innerHeight;

  //   const handleDrag = () => {
  //     if (isScrolling) {
  //       if (elementCoords.x > 0 && elementCoords.x < 100) {
  //         handleScrollLeft();
  //       } else if (elementCoords.x > innerWidthScreen - 100) {
  //         handleScrollRight();
  //       } else if (elementCoords.y > innerHeightScreen - 200) {
  //         handleScrollDown();
  //       }
  //     }
  //   };

  //   handleDrag();
  // }, [isScrolling, elementCoords.x]);

  useEffect(() => {
    setShowingToast(false);
  }, [dirtyData])

  useEffect(() => {
    
    if(globalData.business){
      const horaBase= dayjs(  DateTime.fromFormat( `${dayjs().format('YYYY-MM-DD')} ${globalData.business.startTime.substr(0, 5) }`, 'yyyy-MM-dd hh:mm').toISO()  );
      const horaBaseEnd= dayjs(  DateTime.fromFormat( `${dayjs().format('YYYY-MM-DD')} ${globalData.business.endTime.substr(0, 5) }`, 'yyyy-MM-dd hh:mm').toISO()  );
        
        console.log("horaBase startTime", globalData.business.startTime)
        console.log("horaBase WTF", horaBase)
        
        // TODO: fix typo later
        // @ts-ignore
        setTIMES( calcSideGrid(horaBase,  horaBaseEnd.diff(horaBase, 'hours')  ) )
    }


  }, [globalData])
  




  useEffect(() => {
    refreshBookings();
  }, [currentDate]);





  useEffect(() => {
    refreshBookings();
    // setInfoDnd({
    //   collisionsIds: [],
    // });
    setBookingClash([]);
  }, []);



  useEffect(() => {


    const eventoTouch = (e:any) => {
        // is not near edge of view, exit
        if (e.pageX > 20 && e.pageX < window.innerWidth - 20) return;
        // prevent swipe to navigate back gesture
        e.preventDefault();
    };


    if(principalContentRef.current){
      const element = principalContentRef.current
      element.addEventListener('touchstart', eventoTouch);
    }
    return ()=>{
      principalContentRef?.current?.removeEventListener('touchstart', eventoTouch)
    }

  }, [principalContentRef.current]);



  useEffect(() => {
    if (globalData?.locationsByBusiness) {
      setLocationItem(globalData?.locationsByBusiness[0]);
    }
  }, [globalData?.locationsByBusiness]);

  useEffect(() => {
    setReservasFiltered(
      reservas?.filter((reserva: any) => {
        return !resourceSelect
          ? reserva
          : reserva.boatName === resourceSelect;
      })
    );
  }, [resourceSelect, reservas, setDirtyTemp]);

  useEffect(() => {
    setDirtyTemp(reservasFiltered);
    setIsDirty(false);
  }, [reservasFiltered]);

  const fastAddHandler = (item: any) => {
    navigate("create-booking", {
      state: {
        currentDate,
        locationId: locationItem.id,
        item,
      },
    });
  };

  const { bookingClash, setBookingClash } = useContext(
    ClashAltBookingContext
  );

  // useEffect(() => {
  //   if (!dirtyData) return;

  //   detectCollision();
  // }, [dirtyData, infoDnd.was_moved]);

  // const detectCollision = () => {
  //   const idsUnicos: any = {};

  //   const reservasSinDuplicados = dirtyData
  //     .filter((reserva: any) => reserva.modified)
  //     .filter((reserva: any) => {
  //       if (!idsUnicos[reserva.id]) {
  //         idsUnicos[reserva.id] = true;
  //         return true;
  //       }
  //       return false;
  //     });

  //   const payload = reservasSinDuplicados.map((i: any) => {
  //     return {
  //       id: i.id,
  //       from: parseInt(i.from),
  //       to: parseInt(i.from + i.duration),
  //       boatName: i.boatName,
  //       driverId: parseInt(i.driverObj.id),
  //       grupo: i.driversAdicionales.length > 0 ? true : false,
  //     };
  //   });

  //   // const collisiones = payload.filter((reserva: any) => {
  //   //   return reservasFiltered?.some((item: any) => {
  //   //     if (
  //   //       reserva.boatName === item.boatName &&
  //   //       reserva.id !== item.id
  //   //     ) {
  //   //       const f = item.from;
  //   //       const t = item.from + item.duration;

  //   //       const itemClashing: any = reservasFiltered?.find((reservaF:any) => reservaF.id === item.id)

  //   //       let updatedCollisionsIds = Array.isArray(bookingClash)
  //   //         ? [...bookingClash]
  //   //         : [];

  //   //       const hoursCondition =
  //   //         (f <= reserva.from && t > reserva.from) ||
  //   //         (f < reserva.to && t > reserva.to) ||
  //   //         (f > reserva.from && t < reserva.to) ||
  //   //         (f > reserva.from && t <= reserva.to);

  //   //       if (hoursCondition) {
  //   //         if (itemClashing) {
  //   //            if (!bookingClash.includes(itemClashing.id)) {
  //   //              updatedCollisionsIds.push(itemClashing.id);
  //   //              setBookingClash(updatedCollisionsIds);
  //   //            }
  //   //         }
  //   //       } else {
  //   //         updatedCollisionsIds = updatedCollisionsIds.filter(
  //   //           (id) => id !== itemClashing.id
  //   //         );
  //   //         console.log({ updatedCollisionsIds });
  //   //         setBookingClash(updatedCollisionsIds);
  //   //       }

  //   //       return hoursCondition;
  //   //     }
  //   //     return false;
  //   //   });
  //   // });

  //   let updatedCollisionsIds = Array.isArray(bookingClash)
  //     ? [...bookingClash]
  //     : [];

  //   const collisiones = payload.filter((reserva: any) => {
  //     return reservasFiltered?.some((item: any) => {
  //       if (
  //         reserva.boatName === item.boatName &&
  //         reserva.id !== item.id
  //       ) {
  //         const f = item.from;
  //         const t = item.from + item.duration;

  //         const itemClashing: any[] = reservasFiltered?.filter(
  //           (reservaF: any) => reservaF.id === item.id
  //         );

  //         const hoursCondition =
  //           (f <= reserva.from && t > reserva.from) ||
  //           (f < reserva.to && t > reserva.to) ||
  //           (f > reserva.from && t < reserva.to) ||
  //           (f > reserva.from && t <= reserva.to);

  //         if (hoursCondition) {
  //           itemClashing.forEach((clash) => {
  //             updatedCollisionsIds.push(clash.id);
  //           });
  //           setBookingClash(updatedCollisionsIds);
  //         } else {
  //           const updatedCollisionsIds = [
  //             ...(infoDnd.collisionsIds || []),
  //             ...(bookingClash || []),
  //           ];

  //           const filteredClash = updatedCollisionsIds?.filter(
  //             (b: any) => infoDnd.collisionsIds?.includes(b)
  //           );

  //           setBookingClash(filteredClash);
  //         }

  //         return hoursCondition;
  //       }
  //       return false;
  //     });
  //   });

  //   const handleCollisions = async (collisions: any) => {
  //     let updatedCollisionsIds = Array.isArray(infoDnd.collisionsIds)
  //       ? [...infoDnd.collisionsIds]
  //       : [];

  //     for (const collision of collisions) {
  //       updatedCollisionsIds.push(collision.id);

  //       setInfoDnd({
  //         ...infoDnd,
  //         collisionsIds: updatedCollisionsIds,
  //       });
  //     }
  //   };

  //   handleCollisions(collisiones);
  // };

  const confirmEditSchedule = () => {
    const idsUnicos: any = {};


    console.log("collisiones dirtyData", dirtyData)


    const reservasSinDuplicados = dirtyData
      .filter((reserva: any) => reserva.modified)
      .filter((reserva: any) => {
        // Comprobamos si el id de la reserva ya existe en el objeto auxiliar
        if (! idsUnicos[reserva.id]) {
          // Si no existe, lo añadimos al objeto auxiliar
          idsUnicos[reserva.id] = true;
          // Mantenemos la reserva en el nuevo array filtrado
          return true;
        }
        // Si el id ya existe, descartamos la reserva duplicada
        return false;
      });

    console.log("collisiones reservasSinDuplicados", reservasSinDuplicados)

      

    const payload = reservasSinDuplicados.map((i: any) => {
      return {
        id: i.id,
        from: parseInt(i.from),
        to: parseInt(i.from + i.duration),
        boatName: i.boatName,
        driverId: parseInt(i.driverObj.id),
        grupo: i.driversAdicionales.length > 0 ? true : false,
      };
    });

    const collisiones = payload.filter((reserva: any) => {

      console.log("collisiones check reserva de listaSinDup", reserva)  
      /// ojo, estaba el some sobre "reservasFiltered", o sea, sobre un estado pasado, por eso solo funcionaría sobre un solo cambio
      return dirtyData?.some((item: any) => {
              console.log("collisiones with", item)  
                
                if (
                  item.boatName &&
                  reserva.boatName === item.boatName &&
                  reserva.id !== item.id
                ) {
                  console.log("collisiones COL", reserva.id, item.id) 
                  const f = item.from;
                  const t = item.from + item.duration;

                  const hoursCondition =
                    (f <= reserva.from && t > reserva.from) ||
                    (f < reserva.to && t > reserva.to) ||
                    (f > reserva.from && t < reserva.to) ||
                    (f > reserva.from && t <= reserva.to);

                    console.log("collisiones check RETURN hoursCondition", hoursCondition)
                  return hoursCondition;
                }else
                {
                  console.log("collisiones check RETURN", false)
                  return false;
                }

      });
    
    });

    const delay = (ms: any) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    const handleCollisions = async (collisions: any) => {
      for (const collision of collisions) {
        if (!showingToast) {
          setShowingToast(true);
          toast(
            `Collision with ${collision.boatName} at ${
              getTimeWithPrefixFromOffset(collision.from).value
            } ${getTimeWithPrefixFromOffset(collision.from).prefix}`,
            {
              icon: "⚠️",
            }
          );
          setTimeout(() => {
            setShowingToast(false);
          }, 4000);
          await delay(500);
        }
      }
    };

    if (!collisiones || collisiones?.length === 0) {
      const payloadWithoutBoatName = payload.map((i: any) => ({
        id: i.id,
        from: getTimeWithPrefixFromOffset(i.from),
        to: getTimeWithPrefixFromOffset(i.to),
        driverId: i.driverId,
        grupo: i.grupo,
      }));


      // alert(JSON.stringify(payloadWithoutBoatName, null, 2))
      // return;

      editScheduleMutation({
        variables: {
          data: payloadWithoutBoatName,
          dataAux: {dirtyData}
        },
      })
        .then((res) => {
          if (res.data.editSchedule.status === 200) {
            if (!showingToast ) {
              setShowingToast(true);
              setHasChangeBooking(!hasChangeBooking);
              // setInfoDnd({
              //   ...infoDnd,
              //   collisionsIds: [],
              // });
              const notify = () =>
                toast.success("Schedule edited successfully");
              notify();

              setDirtyData(null);
              setIsDirty(false);
              refreshBookings();
              
              setTimeout(() => {
                setShowingToast(false);
              }, 4000);
            }
          } else {
             if (!showingToast ) {
              setShowingToast(true);
              
              const notify = () =>
                toast(
                  `${res.data.editSchedule.message}`,
                  {
                    icon: "⚠️",
                  }
                );
              notify();
              setTimeout(() => {
                setShowingToast(false);
              }, 4000);
            }
          }
        })
        .catch((err) => {
          const notify = () =>
            toast("Error on schedule edit", {
              icon: "⚠️",
            });
          notify();
          console.log("error editScheduleMutation", err);
          Sentry.captureException(err);
        });
    } else {
      console.log("collisiones", collisiones)
      handleCollisions(collisiones);
      return;
    }
  };

  const cancelEditSchedule = () => {
    // console.log("dirtyTemp", dirtyTemp);
    // console.log("dirtyTemp", dirtyTemp === reservas);
    // setInfoDnd({
    //   ...infoDnd,
    //   collisionsIds: [],
    // });
    setDirtyData(null);
    setBookingClash([]);
    setReservas(dirtyTemp);
    setIsDirty(false);
  };

  const handleContenedorAgendaScroll = () => {
    // if (
    //   principalContentRef.current &&
    //   principalContentHeaderRef.current
    // ) {
    //   principalContentHeaderRef.current.scrollLeft =
    //     principalContentRef.current.scrollLeft;
    // }
  };

  const handleContenedorHeaderAgendaScroll = () => {
    if (
      principalContentRef.current &&
      principalContentHeaderRef.current
    ) {
      principalContentRef.current.scrollLeft =
        principalContentHeaderRef.current.scrollLeft;
    }
  };

  if (loadingGlobalData) return <SpinnerCircle />;


  console.log("HOME RENDERING...");
  

  return (
    <LayoutAdmin pb="75px">
      <ModalLocation
        onChangeLocation={(location: any) =>
          setLocationItem(location)
        }
        data={globalData?.locationsByBusiness}
        isOpen={isOpenLocationModal}
        onClose={onCloseLocationModal}
      />
      <ModalOptions
        isOpen={isOpenOptionsModal}
        onClose={onCloseOptionsModal}
        onOpenBoatFilterModal={onOpenFilterBoatsModal}
        onOpenModalCalendar={onOpenModalCalendar}
      />

      <ModalFilterBoats
        isOpen={isOpenFilterBoatsModal}
        onClose={onCloseFilterBoatsModal}
      />

      <ModalCalendar
        isOpen={isOpenModalCalendar}
        onClose={onCloseModalCalendar}
      />

      <div  className="     ">
        <Header
          currentLocation={locationItem}
          onOpenLocationModal={onOpenLocationModal}
          onOpenOptionsModal={onOpenOptionsModal}
        />

        <Days reservas={reservas} onChangeDate={setCurrentDate} />


        {reservasFiltered?.length === 0 ? (
          loadingBooking ? (
            <FullScreenSpinner />
          ) : (
            <div className="mt-12">
              <div className="grid place-items-center">
                <img
                  src="/illustration/empty-reservation.svg"
                  alt=""
                  className="w-[200px]"
                />
                <p className="text-center font-bold text-2xl text-borderLightBlue mt-5">
                  Oops!
                </p>
                <p className="mt-1 text-center text-xl text-darkBlue">
                  There are no
                  <br />
                  reservations today
                </p>
              </div>
            </div>
          )
        ) : (
          <div className="  overflow-hidden select-none mt-5   ">
            {/* <div className="grid top-0 left-0 bg-white sticky z-50 shadow-md mt-[57px] gap-[7px]">
            <div className="z-[200] w-[65px] h-8 bg-white -bottom-8 left-0 absolute" />

            {TIMES.map((time) => (
              <div
                className="grid justify-center w-[35px] h-full"
                key={time.id}
              >
                <p className="font-bold text-darkBlue text-center leading-[0.95] mt-[3px]">
                  {time.start.split(" ")[0]} <br />
                  <span className="text-sm font-normal">
                    {time.start.split(" ")[1]}
                  </span>
                </p>
              </div>
            ))}
          </div> */}

            {/* <div style={{backgroundColor:'blue', top:0, left:0, position:'absolute', zIndex:100}}>
  coords:{ principalContentRef.current?.scrollLeft  }
  </div> */}

            {/* <div style={{backgroundColor:'green', position:'absolute', top:0, right:0, height:'100%', width:'100px', opacity:0.2, zIndex:-1}}>
</div> */}

            {loadingBooking ? (
              <div className="py-32 z-50 flex justify-center relative top-0 left-0">
                <SpinnerCircle />
              </div>
            ) : (
              <>
                {/* <div
                  id="contenedorAgendaHeader"
                  className="overflow-scroll  gap-[15px]  hide-scrollbar  flex-1 absolute top-0  left-0 right-0 "
                  ref={principalContentHeaderRef}
                  onScroll={handleContenedorHeaderAgendaScroll}
                >
                  <PocHeader
                    // @ts-ignore
                    principalContentHeaderRef={
                      principalContentHeaderRef
                    }
                    dirtyData={dirtyData}
                    // setElementCoords={setElementCoords}
                    // setIsScrolling={setIsScrolling}
                    loaderDriverHoursPerDay={loaderDriverHoursPerDay}
                    hasChangeBooking={hasChangeBooking}
                    setLoaderDriverHoursPerDay={
                      setLoaderDriverHoursPerDay
                    }
                    fastAdd={fastAddHandler}
                    reservas={reservasFiltered}
                    handleDirty={(value: any) => {
                      setIsDirty(value);
                    }}
                    setDirtyData={setDirtyData}
                  />
                </div> */}

                <div
                  id="contenedorAgenda"
                  ref={principalContentRef}
                  onScroll={handleContenedorAgendaScroll}
                  className="overflow-auto     hide-scrollbar      left-0 right-0 flex "
                >
                  {/* @ts-ignore */}

                  <div className=" mt-[52px]  flex flex-col gap-[7px]  ">
                    {/* TODO: fix typo later */}
                    {TIMES.map((time: any) => (
                      <div
                        className="grid justify-center w-[35px] h-[127px] rounded-md  bg-gradient-to-b from-white to-slate-50  "
                        key={time.id}
                      >
                        <p className="font-bold text-darkBlue text-center leading-[0.95] mt-[3px]">
                          {time.start.split(" ")[0]} <br />
                          <span className="text-sm font-normal">
                            {time.start.split(" ")[1]}
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>

                  <div className="relative ml-[7px]  select-none">
                    <Poc
                      // @ts-ignore
                      principalContentHeaderRef={
                        principalContentHeaderRef
                      }
                      dirtyData={dirtyData}
                      setHasChangeBooking={setHasChangeBooking}
                      // principalContentRef = {principalContentRef}
                      // setElementCoords={setElementCoords}
                      // setIsScrolling={setIsScrolling}
                      hasChangeBooking={hasChangeBooking}
                      setLoaderDriverHoursPerDay={
                        setLoaderDriverHoursPerDay
                      }
                      fastAdd={fastAddHandler}
                      reservas={reservasFiltered}
                      handleDirty={(value: any) => {
                        setIsDirty(value);
                      }}
                      setDirtyData={setDirtyData}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {isDirty ? (
          <div className="flex fixed z-[100] gap-x-3 bottom-[20px] right-[15px]">
            <button
              type="button"
              onClick={cancelEditSchedule}
              className="grid place-items-center text-xl rounded-md shadow-2xl shadow-neutral-500 text-white bg-danger w-16 h-16 opacity-100"
            >
              <FaTimes />
            </button>
            <button
              type="button"
              onClick={confirmEditSchedule}
              disabled={loadingEditSchedule}
              className="grid place-items-center text-xl rounded-md shadow-2xl shadow-neutral-500 text-white bg-success w-16 h-16 opacity-100"
            >
              {loaderDriverHoursPerDay || loadingEditSchedule ? (
                <Spinner />
              ) : (
                <FaCheck />
              )}
            </button>
          </div>
        ) : (
          <ButtonAdd />
        )}
      </div>
    </LayoutAdmin>
  );
}
