import { useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";
import { useQuery } from "@apollo/client";
import { BsCheckCircleFill, BsClockFill } from "react-icons/bs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import BackButton from "../components/BackButton";
import { BOOKING_EMPLOYEE_SCHEDULE } from "../graphql/querys";
import { useAuthContext } from "../providers/AuthProvider";
import { useFilterByDateBookings } from "../hooks/useFilterByDateBookings";
import Spinner from "../components/Spinner";

dayjs.extend(customParseFormat);

function DriverConfirmSubItem({ booking }: any) {
  const statusBooking = booking?.estado;

  return (
    <div className="grid items-center grid-cols-2 p-[15px]">
      <div>
        <p className="text-darkBlue text-center">
          {booking?.employee.name}
        </p>
      </div>
      <div className="flex justify-center">
        <p
          className={`${
            statusBooking === "REJECTED"
              ? "text-danger"
              : statusBooking === "CONFIRMED"
              ? "text-success"
              : "text-darkYellow"
          } text-center text-3xl`}
        >
          {statusBooking === "REJECTED" ? (
            <FaTimesCircle />
          ) : statusBooking === "CONFIRMED" ? (
            <BsCheckCircleFill />
          ) : (
            <BsClockFill />
          )}
        </p>
      </div>
    </div>
  );
}

function DriverConfirmItem({ bookings }: any) {
  return (
    <>
      <div className="mb-5">
        <div>
          <div className="grid grid-cols-2 items-center p-5 bg-lightPrimary">
            <div>
              <p className="text-xl uppercase text-center font-bold text-darkBlue">
                Driver
              </p>
            </div>
            <div>
              <p className="text-xl font-bold text-darkBlue uppercase text-center">
                Status
              </p>
            </div>
          </div>

          <div className="container-driver-confirm">
            {bookings?.map((booking: any) => (
              <DriverConfirmSubItem
                key={booking.id}
                booking={booking}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default function DriverConfirm() {
  const { globalData } = useAuthContext();
  const [filteredReservations, setFilteredReservations] =
    useState<any>();

  const { data, loading } = useQuery(BOOKING_EMPLOYEE_SCHEDULE, {
    variables: {
      businessId: globalData?.business.id,
    },
    fetchPolicy: "network-only",
  });

  console.log(dayjs().startOf("day").format());

  useEffect(() => {
    if (!data && loading) return;

    const reservationsFiltered = useFilterByDateBookings(
      data?.bookingsEmployeeSchedule
    );
    setFilteredReservations(reservationsFiltered);
  }, [data, loading]);

  const reservationsOrdered = filteredReservations?.sort(
    (a: any, b: any) =>
      new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  return (
    <div>
      <BackButton goBackRoute="/" title="Driver confirmation" />

      <div className="grid px-5 pb-5">
        {loading ? (
          <div className="grid place-items-center py-8">
            <Spinner />
          </div>
        ) : (
          reservationsOrdered?.map((booking: any) => (
            <div
              className="mb-5 border-b border-[#d9d9d9]"
              key={booking.id}
            >
              <p className="text-2xl font-bold text-darkBlue mb-[10px]">
                {dayjs(booking?.date).format("MMMM DD")}
              </p>
              <DriverConfirmItem
                key={booking.id}
                bookings={booking.bookings}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
}
