import { useState, useContext, useEffect } from "react";
import dayjs from "dayjs";
import { useApolloClient, useQuery } from "@apollo/client";

import { useAuthContext } from "../../providers/AuthProvider";
import {
  BOOKING_BY_EMPLOYEE_QUERY,
  BOOKING_EMPLOYEE_SCHEDULE,
} from "../../graphql/querys";

import LayoutAdmin from "../../components/Layout/Driver";
import DriverHeader from "../../components/Driver/Header";
import Days from "../../components/Home/Days";
import DriverBooking from "../../components/Driver/Booking";
import { CurrentDateContext } from "../../context/CurrentDateContext";
import ModalConfirmBooking from "../../components/Modals/Confirm";

import ScheduleImageDemo from "../../assets/schedule-demo.png";
import { tConvert } from "../../utils/time";
import Spinner from "../../components/Loader/Spinner";

interface Driver {
  id: string;
  name: string;
  image: string;
}

interface Booking {
  id: string;
  clientName: string;
  startTime: string;
  endTime: string;
  drivers: Driver[];
  resource: {
    id: string;
    name: string;
    image: string;
  };
  createdAt: string;
}

export default function DriverHome() {
  const isEmptyReservations = !!true;

  const [showDay, setShowDay] = useState<boolean>(false);
  const [fechaRef, setFechaRef] = useState(dayjs());
  const { globalData } = useAuthContext();
  const apolloClient = useApolloClient();

   const { currentDate, setCurrentDate } = useContext(
     CurrentDateContext
   );

  const hora = globalData?.business.publishTime;
  const [horaPart, minutoPart, segundoPart, milisegundoPart] =
    hora.split(/:|\./);

  const datePublishTime = new Date();
  datePublishTime.setHours(parseInt(horaPart, 10));
  datePublishTime.setMinutes(parseInt(minutoPart, 10));
  datePublishTime.setSeconds(parseInt(segundoPart, 10));
  datePublishTime.setMilliseconds(parseInt(milisegundoPart, 10));

  const publishTime = dayjs(datePublishTime.toISOString()).format('hh:mm a')
  const datePublish = dayjs(currentDate).subtract(globalData?.business.publishDaysBefore).format('MMM DD')
  
  const [bookingIds, setBookingIds] = useState<any>([]);

  const [reservasOnlyDate, setReservasOnlyDate] = useState<any>([]);

  const [locationItem, setLocationItem] = useState<any>(null);
  const [date, setDate] = useState(dayjs(currentDate).format());
  const [reservas, setReservas] = useState<any>([]);

  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const [listConfirmations, setListConfirmations] = useState<
    string[] | undefined[]
  >([undefined]);

  const [confirmationsBooking, setConfirmationsBooking] =
    useState(null);

  const {
    data: bookingsByEmployeeData,
    refetch: refetchBookingsByEmployee,
    loading,
  } = useQuery(BOOKING_BY_EMPLOYEE_QUERY, {
    variables: {
      date: dayjs(currentDate).toDate(),
    },
    fetchPolicy: "network-only",
  });

  console.log(bookingsByEmployeeData);

  useEffect(() => {
    refetchBookingsByEmployee();
  }, [isUpdated]);

  const { data, loading: loadingEmployeeSchedule } = useQuery(
    BOOKING_EMPLOYEE_SCHEDULE,
    {
      variables: {
        businessId: globalData?.business.id,
        date: dayjs(currentDate).startOf("day").format(),
      },
      fetchPolicy: "network-only",
    }
  );

  console.log("hf globaldata", globalData?.me.id);
  console.log("hf data", data);

  const currentState = data?.bookingsEmployeeSchedule.find(
    (booking: any) => +booking.employee.id === +globalData?.me.id
  );

  const [loadingBooking, setLoadingBooking] =
    useState<boolean>(false);

  const [showModalConfirmBooking, setShowModalConfirmBooking] =
    useState<string>(currentState?.estado);

  useEffect(() => {
    setShowModalConfirmBooking(currentState?.estado);
  }, [data, currentState]);

  const refreshBookings = async () => {
    setLoadingBooking(true);
    refetchBookingsByEmployee();

    // apolloClient
    //   .query({
    //     fetchPolicy: "network-only",
    //     query: BOOKING_BY_EMPLOYEE_QUERY,
    //     variables: {
    //       date: dayjs(currentDate).toDate(),
    //     },
    //   })
    // bookingsByEmployee({
    //   variables: {
    //     date: dayjs(currentDate).toDate(),
    //   },
    // })
    // .then(async (res: any) => {
    //   console.log(res)

    // const { bookingsByEmployee } = await res.data;
    // const response = await res?.data.bookingsByEmployee;
    // console.log("BBBBBB", bookingsByEmployee);
    // console.log("res bookingsByEmployee", bookingsByEmployee);

    // {
    //   id: "1",
    //   clientName: "Santiago Wasmosy",
    //   startTime: "07am",
    //   endTime: "08am",
    //   drivers: [
    //     {
    //       id: "1",
    //       name: "Tom Collins",
    //       image: "https://bit.ly/43cFGQF",
    //     },
    //     {
    //       id: "2",
    //       name: "Harry L",
    //       image: "https://bit.ly/43cFGQF",
    //     },
    //   ],
    //   resource: {
    //     id: "1",
    //     image: "https://bit.ly/43e6jVm",
    //     name: "Nautique S23",
    //   },
    //   createdAt: "2011-10-05T14:48:00.000Z",
    // }

    //setReservas(bookingsByEmployeeData?.bookingsByEmployee);
    // })
    // .catch((err: any) => {
    //   console.log("err", err);
    // })
    // .finally(() => {
    //   setLoadingBooking(false);
    // });
  };

  useEffect(() => {
    console.log("bookingsByEmployeeData", bookingsByEmployeeData);
    if (bookingsByEmployeeData?.bookingsByEmployee)
      setReservas(bookingsByEmployeeData.bookingsByEmployee);
  }, [bookingsByEmployeeData]);

  const refreshFechaRef = () => {
    if (globalData) {
      const horaMinutos = globalData.business.publishTime.substring(
        0,
        5
      );
      const arrayHoraMinutos = horaMinutos.split(":");

      const horas = arrayHoraMinutos[0];
      const minutos = arrayHoraMinutos[1];
      const r = dayjs()
        .subtract(globalData.business.publishDaysBefore, "day")
        .set("hour", horas)
        .set("minute", minutos);
      setFechaRef(r);

      console.log("globaldata business", r.format());
    }
  };

  useEffect(() => {
    if (currentDate) {
      refreshBookings();
      refreshFechaRef();

      // console.log("currentDate dias",  currentDate,
      // dayjs().startOf('day').format()   )

      let show = false;
      const diasRef = dayjs(currentDate)
        .startOf("day")
        .diff(dayjs().startOf("day"), "days");

      if (diasRef < globalData.business.publishDaysBefore)
        show = true;
      else if (diasRef === globalData.business.publishDaysBefore) {
        const horaMinutos = globalData.business.publishTime.substring(
          0,
          5
        );
        const arrayHoraMinutos = horaMinutos.split(":");

        const horas = arrayHoraMinutos[0];
        const minutos = arrayHoraMinutos[1];
        const r = dayjs().set("hour", horas).set("minute", minutos);
        console.log(
          "r de comp",
          r.format(),
          r.diff(dayjs(), "minute")
        );
        if (r.diff(dayjs(), "minute") <= 0) show = true; // si ya paso la hora del dia de publicacion mostramos dia
      }

      setShowDay(show);

      //console.log("currentDate DIAS -------->",  diasRef   )
    }
  }, [currentDate]);

  useEffect(() => {
    refreshBookings();
  }, [currentDate, isUpdated, loading]);

  useEffect(() => {
    console.log("reservas", reservas);
    if (reservas) {
      const neccesaryInfoReservation = reservas?.map(
        (reserva: any) => {
          return {
            id: reserva.id,
            startDate: reserva.startDate,
            endDate: reserva.endDate,
            type: reserva.type,
            employees: reserva.bookingEmployees.map(
              (employee: any) => employee?.employee.name
            ),
            clientName: reserva.clientName,
            resourceName: !reserva?.resource
              ? null
              : reserva?.resource.name,
          };
        }
      );

      setBookingIds(reservas?.map((reserva: any) => reserva.id));
      const rod = neccesaryInfoReservation?.sort(
        (a: any, b: any) =>
          new Date(a.startDate).getTime() -
          new Date(b.startDate).getTime()
      );

      setReservasOnlyDate(rod);

      // setConfirmationsBooking(
      //   reservas?.map((reserva: any) => {
      //     console.log("setConfirmationsBooking  loop reserva", reserva)
      //     return reserva?.bookingEmployees[0].estado;
      //   })
      // )

      setConfirmationsBooking(
        reservas?.map((reserva: any) => {
          console.log("aaaaaa", reserva?.bookingEmployees);
          console.log(
            "test en set confirm booking",
            reserva?.bookingEmployees.find(
              (i: any) => i.employee.id === globalData.me.id
            )
          );
          return reserva?.bookingEmployees.find(
            (i: any) => i.employee.id === globalData.me.id
          )?.estado;
        })
      );
    }
  }, [reservas, setReservasOnlyDate, setBookingIds]);

  return (
    <LayoutAdmin>
      <DriverHeader
        bookingIds={bookingIds}
        listConfirmations={listConfirmations}
        setIsUpdated={setIsUpdated}
        refetchBookingsByEmployee={refetchBookingsByEmployee}
      />

      <Days isDriver={true} onChangeDate={setCurrentDate} />

      <div className="pb-8">
        {!loadingEmployeeSchedule && showDay && (
          <ModalConfirmBooking
            show={showModalConfirmBooking === "PENDING"}
            onClose={setShowModalConfirmBooking}
            title="Confirm your schedule"
          >
            <p className="text-center">
              Press the check box in order to confirm your schedule
            </p>

            <div className="grid place-items-center mt-5">
              <img
                src={ScheduleImageDemo}
                className="w-[32px]"
                alt=""
              />
            </div>

            <button
              className="text-center w-full mt-5 text-darkBlue bg-transparent text-xl"
              onClick={() => setShowModalConfirmBooking("close")}
            >
              VIEW SCHEDULE
            </button>
          </ModalConfirmBooking>
        )}

        {loadingEmployeeSchedule ? (
          <div className="grid place-items-center py-12">
            <Spinner />
          </div>
        ) : !showDay ? (
          <div className="mt-12">
            <div className="grid place-items-center">
              <img
                src="/illustration/not-available-yet-illustration.svg"
                alt=""
              />
              <p className="text-center font-bold text-2xl text-borderLightBlue">
                Not Available Yet
              </p>
              <p className="mt-[5px] text-center text-xl text-darkBlue">
                Your Schedule will
                <br />
                be posted at {publishTime}
                <br />
                on {datePublish}
              </p>
            </div>
          </div>
        ) : loading ? (
          <div className="grid place-items-center py-12">
            <Spinner />
          </div>
        ) : reservasOnlyDate?.length > 0 ? (
          <div className="flex flex-col gap-y-8 mt-8">
            {reservasOnlyDate?.map((reservation: Booking) => (
              <DriverBooking
                key={reservation.id}
                booking={reservation}
              />
            ))}
          </div>
        ) : (
          <div className="mt-12">
            <div className="grid place-items-center">
              <img
                src="/illustration/not-available-yet-illustration.svg"
                alt=""
              />
              <p className="text-center font-bold text-2xl text-borderLightBlue">
                No reservations
              </p>
              <p className="mt-[5px] text-center text-xl text-darkBlue">
                No reservations
                <br />
                were made today
              </p>
            </div>
          </div>
        )}
      </div>
    </LayoutAdmin>
  );
}
