import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import BackButton from "../../../components/BackButton";
import Spinner from "../../../components/Spinner";
import { CREATE_LOCATION_MUTATION } from "../../../graphql/mutations";

import "react-toastify/dist/ReactToastify.css";

export default function CreateLocation() {
  const [location, setLocation] = useState("");
  const [createLocation, { loading }] = useMutation(
    CREATE_LOCATION_MUTATION
  );
  const navigate = useNavigate();

  const handleCreate = async (e: React.FormEvent) => {
    e.preventDefault();

    createLocation({
      variables: {
        data: {
          name: location,
        },
      },
    }).then((res: any) => {
      console.log(res);
      if (res.data?.createLocation.status === 200) {
        const notify = () =>
          toast.success("Location created successfully");
        notify();
        return navigate(-1);
      }

      const notify = () =>
        toast("A location with that name already exists", {
          icon: "⚠️",
        });
      notify();
    });
  };

  return (
    <div>
      <BackButton
        goBackRoute="/list-locations"
        title="ADD LOCATION"
      />

      <form
        className="flex flex-col justify-between h-[calc(100vh_-_88px_-_32px)] px-5"
        onSubmit={handleCreate}
      >
        <input
          className="form-control-input"
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Location's name"
        />
        <button
          className={`bg-darkBlue h-[54px] text-white rounded-md font-medium text-lg ${
            !location || location.length <= 2
              ? "opacity-50"
              : "opacity-100"
          }`}
          type="submit"
          disabled={!!loading}
        >
          {loading ? <Spinner /> : "Add Location"}
        </button>
      </form>
    </div>
  );
}
