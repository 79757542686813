import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import { FaHome } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { CurrentDateContext } from "../../../context/CurrentDateContext";
import { useAuthContext } from "../../../providers/AuthProvider";

export default function DriverLayout({ children, ...rest }: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentDate } = useContext(CurrentDateContext)

  const { globalData } = useAuthContext();
  
  const handleTouchStart = () => {
    const pressTimer = setTimeout(() => {
      navigate("/macro-view");
    }, 700);

    const handleTouchEnd = () => {
      clearTimeout(pressTimer);
    };

    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("touchend", handleTouchEnd);
    };
  };

  useEffect(() => {
    const button: any = document.getElementById("myButton"); // Reemplaza 'myButton' con el ID de tu botón

    const today = dayjs();
    const day = dayjs(currentDate)
      .subtract(globalData.business.publishDaysBefore, "day")
      .hour(parseInt(globalData.business.publishTime.slice(0, 2)))
      .minute(parseInt(globalData.business.publishTime.slice(3, 5)))
      .second(0);

    if (day.isAfter(today)) return
    
    button.addEventListener("touchstart", handleTouchStart);

    return () => {
      button.removeEventListener("touchstart", handleTouchStart);
    };
  }, [currentDate]);
  
  return (
    <div className="max-w-[480px] mx-auto" {...rest}>
      {children}

      <div
        className={`grid place-items-center fixed z-50 bottom-5 right-4 w-14 rounded-md text-white text-xl h-14 ${
          location.pathname !== "/"
            ? "bg-darkBlue"
            : "bg-neutral-500"
        }`}
        onClick={() =>
          location.pathname === "/"
            ? navigate("/driver-settings")
            : navigate("/")
        }
      >
        <div
          id="myButton"
          className="hover:ring-2 hover:ring-offset-2 hover:ring-darkBlue absolute z-[70] top-0 left-0 w-full h-full rounded-md"
        />

        <span>
          {location.pathname !== "/" ? (
            <FaHome />
          ) : (
            <FaGear />
          )}
        </span>
      </div>
    </div>
  );
}
