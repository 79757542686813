import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";

export default function BackButton({
  goBackRoute,
  title,
  color,
  smallPadding = false,
  ...rest
}: any) {
  const navigate = useNavigate();

  return (
    <div
      className={`flex items-center ${
        !smallPadding ? "p-5" : "px-5 py-[18px]"
      }`}
    >
      <button
        className="grid place-items-center w-12 h-12 bg-darkBlue text-white rounded-md min-w-[initial]"
        onClick={() =>{ 
          // navigate(goBackRoute, { replace: true })
          navigate(-1);
        }
      }
      >
        <FaAngleLeft />
      </button>
      <p
        className={`text-xl uppercase font-semibold ml-4`}
        style={{ color }}
        {...rest}
      >
        {title}
      </p>
    </div>
  );
}
