import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";

import { EMPLOYEES_QUERY } from "../../../graphql/querys";
import BackButton from "../../../components/BackButton";
import CardEdit from "../../../components/Settings/CardEdit";
import { Button } from "../../../ui";
import { DriverListLoader } from "../../../components/Loader";
import ButtonAdd from "../../../components/ButtonAdd";

export default function ListDrivers() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, loading, error, refetch } = useQuery(EMPLOYEES_QUERY);

  useEffect(() => {
    refetch();
  }, [location]);

  const onDelete = () => {
    refetch();
  };

  if (loading) return <DriverListLoader />;
  if (error) return <p>Error...</p>;

  return (
    <div className="pb-8">
      <BackButton goBackRoute="/settings" title="Drivers" />
      <div className="mb-5 px-5">
        <Button
          color="vvas.darkBlue"
          bgColor="white"
          border="1px solid"
          borderColor="vvas.darkBlue"
          onClick={() => navigate("/hour-confirm")}
        >
          Schedule Posting
        </Button>
      </div>

      <div className="grid px-5 gap-5 grid-cols-2">
        {data.employees.map((employee: any) => (
          <CardEdit
            type="driver"
            onDelete={onDelete}
            refetch={refetch}
            key={employee.id}
            edit={`/edit-driver/${employee.id}`}
            route="/create-driver"
            pageIntern
            info={{
              id: employee.id,
              name: employee.name,
              code: employee.code,
              picture: employee.picture,
              color: employee.bgColor,
            }}
          />
        ))}
      </div>

      <ButtonAdd pageIntern route="/create-driver" bottom="30px" />
    </div>
  );
}
